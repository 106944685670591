import React, { useContext } from "react";
import { Stack, Button, ButtonGroup, Box, Typography } from "@mui/material";

import { Context } from "../../context/Context";

const MerchOrDonation = ({ handleMerch, handleDonation, artistName, footerButton1, footerButton2, isCameraOn, hasMerchUrl, hasDonationUrl }) => {
  const { theme } = useContext(Context);

  const buttonGroupTitle = {
    fontSize: {
      xs: "1.3rem",
      sm: "1.5rem",
      md: "1.6rem",
      xl: "1.7rem"
    },
    marginBottom: "1rem",
    marginTop: "1rem",
    
    marginTop: {
      xs: "1.2rem",
    },
    marginBottom: {
      xs: "1rem",
    },
    lineHeight: "2rem",
    fontFamily: `"Varela Round", sans-serif`,
    color: "azure",
    ...theme.footer,
  };

  const buttonStyle = {
    fontFamily: `"Varela Round", sans-serif`,
    "&:hover": {
      bgcolor: "#3a3cc4",
    },
    fontSize: "1.2rem",
    padding: {
      xs: "1rem 1.4rem",
      sm: "1rem 1.5rem",
      md: "1rem 1.6rem",
      xl: "1rem 2.7rem",
    },
    fontSize: {
      xs: "1rem",
      sm: "1.2rem",
      md: "1.3rem",
      xl: "1.2rem"
    },
    textTransform: "none"
  };

  return (
    <Box
      sx={{
        // display: "flex",
        // alignItems: "center",
        textAlign: "center",
        fontFamily: `"Varela Round", sans-serif`,
      }}
    >
      <Stack spacing={1} direction="column" >
        {!isCameraOn && <Typography sx={buttonGroupTitle}>Support {artistName}!</Typography>}

        <ButtonGroup
          variant="contained"
          orientation="horizontal"
          color="primary"
          aria-label="alignment button group"
          // sx={{marginTop: isCameraOn && "5rem"}}
        >
          {hasMerchUrl && <Button onClick={handleMerch} sx={buttonStyle}>
            {footerButton1 ? footerButton1 : "Buy Merch"}
          </Button>}
          {hasDonationUrl && <Button onClick={handleDonation} sx={buttonStyle} >
            {footerButton2 ? footerButton2 : `Tip ${artistName}`}
          </Button>}
        </ButtonGroup>
      </Stack>
    </Box>
  );
};

export default MerchOrDonation;
