import React from "react";
import { HOST } from "../../constants/frontAppConstants";

const VideoStreamTest = ({ height, width, channelId }) => {
  console.log("process.env:", process.env);
  const channel = channelId ? channelId : "MirceaGogoncea";
  // const twitchURL = `https://player.twitch.tv/?channel=${channel}&parent=${HOST}`;
    const twitchURL = `https://player.twitch.tv/?channel=${channel}&parent=app.cicadamusic.net&parent=localhost&parent=markeybass.com&parent=www.markeybass.com`;
    // const twitchURL = `https://player.twitch.tv/?channel=${channel}&parent=markeybass.com&autoplay=true`;
  return (
    <>
      <iframe
        src={twitchURL}
        // height={"100%"}
        // width={"100%"}
        height={height ? height : "520"}
        width={width ? width : "340"}
        // height="720"
        // width="1280"
        // height="360"
        // width="640"
        allowFullScreen
      ></iframe>
    </>
  );
};

export default VideoStreamTest;

const videoStreams = [
  {
    stremProvider: "twitch",
    streamName: "Mircea",
    channelId: "MirceaGogoncea",
  },
  {
    stremProvider: "twitch",
    streamName: "Mircea",
    channelId: "MirceaGogoncea",
  },
];
