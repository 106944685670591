import React from "react";
import { HOST } from "../../constants/frontAppConstants";

const VideoStream = ({ height, width, channelId }) => {
  // console.log("process.env:", process.env);
  const channel = channelId ? channelId : "MirceaGogoncea";
  // const twitchURL = `https://player.twitch.tv/?channel=${channel}&parent=${HOST}&autoplay=true`;
  const twitchURL = `https://player.twitch.tv/?channel=${channel}&parent=${HOST}&parent=markeybass.com&parent=www.markeybass.com&autoplay=true`;
  return (
    <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0 }}>
      <iframe
        src={twitchURL}
        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
        // height={height ? height : "520"}
        // width={width ? width : "340"}
        allowFullScreen
      ></iframe>
    </div>
    
    // <div style={{ position: 'relative', paddingTop: '56.25%', height: 0 }}>
    //   <iframe
    //     src={twitchURL}
    //     style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
    //     // height={height ? height : "520"}
    //     // width={width ? width : "340"}
    //     allowFullScreen
    //   ></iframe>
    // </div>


    // <div style={{ position: 'relative', paddingBottom: '177.77%', height: 0 }} >
    //   <iframe
    //     src={twitchURL}
    //     style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
    //     allowFullScreen
    //     // height={height ? height : "520"}
    //     // width={width ? width : "340"}
    //   />
    // </div>
    
    // <div style={{ position: 'relative', paddingBottom: '125%', height: 0 }} >
    //   <iframe
    //     src={twitchURL}
    //     style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
    //     allowFullScreen
    //     // height={height ? height : "520"}
    //     // width={width ? width : "340"}
    //   />
    // </div>
  );
};

export default VideoStream;

const streamers = [
  {
    stremProvider: "twitch",
    streamName: "Mircea",
    channelId: "MirceaGogoncea",
  },
  {
    stremProvider: "twitch",
    streamName: "Mircea",
    channelId: "MirceaGogoncea",
  },
];
