import React, { useState, useRef, useContext, Fragment } from "react";
import classes from "./Login.module.css";
import TopBar from "../../components/topbar/TopBar";
import { Link } from "react-router-dom";
import { Context } from "../../context/Context";
import axios from "axios";
import CustomAlert from "../../components/CustomAlert/CustomAlert";
import { PATH_SUFFIX } from "../../constants/frontAppConstants";

import { themeOptions } from "../../context/Context";
import jwt_decode from "jwt-decode";

const Login = () => {
  const [showAlert, setShowAlert] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const emailRef = useRef();
  const passwordRef = useRef();

  const { dispatch, isFetching, errorMessage } = useContext(Context);

  const submitHandler = async (e) => {
    e.preventDefault();
    dispatch({ type: "LOGIN_START" });
    try {
      const res = await axios.post(PATH_SUFFIX + "/auth/login", {
        email: emailRef.current.value,
        password: passwordRef.current.value,
      });
      console.log(res.data);
      const decodedUser = jwt_decode(res.data.token);
      dispatch({ type: "LOGIN_SUCCESS", payload: {...decodedUser, token: res.data.token} });
      setShowAlert(false);
    } catch (err) {
      err.response.data.error
        ? dispatch({ type: "LOGIN_FAILURE", payload: err.response.data.error })
        : dispatch({
            type: "LOGIN_FAILURE",
            payload: "something went wrong...",
          });

      console.error(err.response.data.error);
      setShowAlert(true);
    }
  };

  const removeAlertHandler = () => setShowAlert(false);

  return (
    <div style={{...themeOptions.lightMode, width: "100vw", height: "100vh"}}>
      <TopBar />
      <div className={classes.login}>
        <span className={classes.loginTitle}>Login</span>
        <form className={classes.loginForm} onSubmit={submitHandler}>
          <label htmlFor="email">Email</label>
          <input
            className={classes.loginInput}
            type="text"
            placeholder="Enter your email..."
            ref={emailRef}
          />

          <label htmlFor="password">Password</label>
          <input
            className={classes.loginInput}
            type={!showPassword ? "password" : "text"}
            placeholder="Enter password..."
            ref={passwordRef}
          />
          <div className={classes.showPassword}>
            <input
              type="checkbox"
              onClick={() => {
                setShowPassword(!showPassword);
              }}
            />
            <p>View Password</p>
          </div>
          <button
            type="submit"
            className={classes.loginButon}
            disabled={isFetching}
          >
            Login
          </button>
        </form>
        <Link to="/register">
          <button className={classes.loginRegisterButon}>Register</button>
        </Link>
        {showAlert && (
          <CustomAlert
            msg_1={errorMessage}
            onRemoveAlert={removeAlertHandler}
          />
        )}
      </div>
    </div>
  );
};

export default Login;
