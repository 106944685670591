import TopBar from "./components/topbar/TopBar";
import Home from "./pages/home/Home";
import Settings from "./pages/settings/Settings";
import Single from "./pages/single/Single";
import Write from "./pages/write/Write";
import Login from "./pages/login/Login";
import Register from "./pages/register/Register";
import SingleShow from "./pages/singleShow/SingleShow";
import ThankYou from "./pages/thankyou/ThankYou";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useContext } from "react";
import { Context } from "./context/Context";
import VideoStreamTest from "./pages/videoStreamTest/VideoStreamTest";

function App() {
  const { user, theme } = useContext(Context);
  // console.log("<App> user=",user);
  return (
    <div
      style={{
        width: "100%",
        minHeight: "100%",
        ...theme.general,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <BrowserRouter>
        {/* <TopBar /> */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/register" element={user ? <Home /> : <Register />} />
          <Route path="/login" element={user ? <Home /> : <Login />} />
          {/* <Route path="/write" element={user ? <Write /> : <Register />} /> */}
          <Route
            path="/settings"
            element={user ? <Settings /> : <Register />}
          />
          <Route path="/videoStream" element={<VideoStreamTest />} />
          <Route path="/concert/:concertId" element={<SingleShow />} />
          <Route path="/concertShow/:concertId" element={<Single />} />
          <Route path="/thankyou" element={<ThankYou />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
