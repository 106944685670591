import React, { useContext } from "react";
import { ButtonGroup, Button, Stack } from "@mui/material";
import { Context } from "../../context/Context";

function AdminNonScrollFooter() {
  const { dispatch } = useContext(Context);
  const handleStartLyricsBroadcast = () => {
    dispatch({ type: "UPDATE_SCROLLER", payload: true });
  };
  return (
    <Stack direction="row">
      <Button
        variant="contained"
        color="primary"
        sx={{
          bgcolor: "rgb(94, 72, 146)",
          padding: "2rem 4rem",
          margin: "0 2rem",
          "&:hover": { bgcolor: "rgb(123, 136, 255)", color: "black" },
          textTransform: "none",
          fontSize: "1.7rem",
          fontSize: {
            xs: "1.4rem",
            sm: "1.5rem",
            md: "1.6rem",
            xl: "1.7rem"
          },
          width: "100%"
        }}
        onClick={handleStartLyricsBroadcast}
        aria-label='admin action button'
      >
        Start Lyrics Broadcast
      </Button>
    </Stack>
  );
}

export default AdminNonScrollFooter;
