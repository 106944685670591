import { createContext, useEffect, useReducer } from "react";
import Reducer from "./Reducer";

export const themeOptions = {
  darkMode: {
    modeName: "dark",
    general: {
      backgroundColor: "#222222",
      backgroundColor: "#161a1d",
      color: "azure",
    },
    a: {
      color: "#a2e5ff",
    },
    toggleButton: {
      backgroundColor: "#727272",
    },
    accordeon: {
      // backgroundColor: "#585858",
      // backgroundColor: "#444444",
      // backgroundColor: "#464646",
      // backgroundColor: "rgb(107, 113, 121)",
      backgroundColor: "#1b263b",
      backgroundColor: "#253237",
      backgroundColor: "#20262b",
      
      
      // backgroundColor: "#0b090a",
      // backgroundColor: "#080708",

      color: "azure",
    },
    appBar: {
      backgroundColor: "#0d1b2a",
      // backgroundColor: "#43555f",
      // backgroundColor: "#0b090a",
      backgroundColor: "#0e1113",
      // backgroundColor: "#011627",
    },


    footer: {
      backgroundColor: "#323232",
      color: "azure",
    },
  },

  lightMode: {
    modeName: "light",
    general: {
      color: "black",
      backgroundColor: "#f2f3f5",
      // backgroundColor: "rgb(238,255,238)",
    },
    a: {
      color: "#131b1f",
    },
    toggleButton: {
      backgroundColor: "inherit",
    },
    accordion: {
      color: "black",
      backgroundColor: "#f2f3f5",
      // backgroundColor: "rgb(213, 255, 213)",
    },
    appBar: {
      backgroundColor: "rgb(74, 85, 105)",
    }, 
    footer: {
      // backgroundColor: "#929292",
      // backgroundColor: "#cfcfcf",
      backgroundColor: "#dbe5ea",
      // backgroundColor: "#a9bda9",
      // backgroundColor: "#a4c5a4",

      color: "black",
    },
  },
};

export const purchaseStageOptions = {
  BEFORE_ACTION: "before-action",
  GIVE_EMAIL: "email-form",
  THANK_YOU_POPUP: "thank-you-popup",
};

export const orderTypes = {
  DONATION: "donation",
  MERCH: "merch",
};

export const userRoles = {
  USER: "user",
  PUBLISHER: "publisher",
  ADMIN: "admin",
};

const INITIAL_STATE = {
  user: JSON.parse(localStorage.getItem("cicadaLyricsUser")) || null,
  isScroller: JSON.parse(localStorage.getItem("isScroller")) || false,
  isConcertAdmin: JSON.parse(localStorage.getItem("isConcertAdmin")) || false,
  adminMode: { isAdminLyricsBroadcastMode: false },
  audientIneraction: { activeScroll: true },
  isFetching: false,
  error: false,
  errorMessage: null,
  purchaseStage: null,
  orderType: null,
  concertLyricsStyles: {
    concertId: null,
    songs: {
      /** [sondId]: { pFontSize: "1rem", aFontSize: "2rem" } */
    },
  },
  theme: themeOptions.darkMode,
};

export const Context = createContext(INITIAL_STATE);

export const ContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, INITIAL_STATE);

  useEffect(() => {
    localStorage.setItem("cicadaLyricsUser", JSON.stringify(state.user));
  }, [state.user]);

  return (
    <Context.Provider
      value={{
        user: state.user,
        isScroller: state.isScroller,
        isConcertAdmin: state.isConcertAdmin,
        adminMode: state.adminMode,
        audientIneraction: state.audientIneraction,
        isFetching: state.isFetching,
        error: state.error,
        errorMessage: state.errorMessage,
        purchaseStage: state.purchaseStage,
        orderType: state.orderType,
        concertLyricsStyles: state.concertLyricsStyles,
        theme: state.theme,
        dispatch,
      }}
    >
      {children}
    </Context.Provider>
  );
};
