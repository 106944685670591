import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import classes from "./ConcertCard.module.css";
import { useLocation, useNavigate } from "react-router";
import { Context, userRoles } from "../../context/Context";

const ConcertCard = ({ id, concert }) => {
  // Modyfing createdAt Date string
  const date = new Date(concert.startsAt);
  const concertStartsAt = date.toDateString();

  const location = useLocation();
  const navigate = useNavigate();

  const { dispatch, user } = useContext(Context);

  const handleScroller = (isScroller, isConcertAdmin) => {
    dispatch({ type: "UPDATE_SCROLLER", payload: isScroller });
    dispatch({ type: "UPDATE_IS_CONCERT_ADMIN", payload: isConcertAdmin });

    navigate(`/concert/${id}`);
  };

  const [isConcertOwner, setIsConcertOwner] = useState(false);

  useEffect(() => {
    // console.log({
    //   id1C: concert.user.id,
    //   id2U: user?.id,
    //   role: user?.role,
    //   isTestConcert: concert.isTestConcert,
    // });
    if (
      // TODO: fix this line with ? marks!
      (user && concert?.user?.id === user.id) ||
      (user && user.role === userRoles.ADMIN)
    ) {
      setIsConcertOwner(true);
    }
  }, [concert, user]);

  return (
    <div className={classes.container}>
      {(concert.isTestConcert === true || isConcertOwner === true) && (
        <button
          style={{
            maxWidth: "50%",
            cursor: "pointer",
            alignSelf: "flex-end",
          }}
          onClick={() => handleScroller(true, true)}
        >
          Be the Scroller
        </button>
      )}

      <Link
        onClick={() => handleScroller(false, false)}
        to={`/concert/${id}`}
        className={` link`}
      >
        <div className={classes.concertTitleLink}>
          <span className={classes.concertTitle}>{concert.eventName}</span>
        </div>
        {/* </Link> */}
        <div className={classes.concert} key={id} aria-label="concert-card-image">
          {!concert.photo ? (
            <img
              className={classes.concertImage}
              src="https://images.pexels.com/photos/2382889/pexels-photo-2382889.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
              alt="Concerts pic haven't been uploaded"
              loading="lazy"
            />
          ) : (
            <img
              className={classes.concertImage}
              src={"/concert_photo_uploads/" + concert.photo}
              alt="Concerts pic haven't been uploaded"
              loading="lazy"
            />
          )}
          <div className={classes.concertInfo}>
            {location.search === "" || /\?category=/.test(location.search) ? (
              <span className={classes.concertAuthor}>
                Artist:{" "}
                {/* <Link
                to={`/?user=${
                  concert.user && concert.user.username && concert.user.username
                }`}
                className="link"
              > */}
                <b>
                  {concert.user &&
                    concert.user.username &&
                    concert.user.username}
                </b>
                {/* </Link> */}
              </span>
            ) : (
              <p></p>
            )}
            {/* {categories && (
          <div className={classes.concertCats}>
          {categories.map((category, index) => {
            return (
              <span key={id + index} className={classes.concertCat}>
              {category}
              </span>
              );
            })}
            </div>
          )} */}
            <hr />
            <span className={classes.concertDate}>{concertStartsAt}</span>
            <p className={classes.concertDesc}>{concert.messageToAudience}</p>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default ConcertCard;
