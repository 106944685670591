import React, { useContext, useEffect, useRef, useState } from "react";
import { Context } from "../../context/Context";
import { Box, ImageListItem, Typography } from "@mui/material";

const Info = ({ concert }) => {
  const { theme } = useContext(Context);
  return (
    <Box sx={{ height: "80vh", position: "relative"}}>
      <Box sx={{ height: "80vh" }}>
        <div
          aria-label="info-pannel"
          id="scrollingWindow"
          // ref={scrollingWindowRef}
          style={{
            height: "100%",
            overflowY: "scroll",
            ...theme.accordeon,
            backgroundColor: theme.accordeon?.backgroundColor,
            padding: "1rem",
            paddingBottom: "0",
            textAlign: "center",
            position: "relative",
            zIndex: 3,
          }}
        >
          {concert.photo && concert.photo !== "no-photo.jpg" && (
            <ImageListItem sx={{ marginBottom: "2rem" }}>
              <img
                src={"/concert_photo_uploads/" + concert.photo}
                alt={concert.eventName}
              />
            </ImageListItem>
          )}
          <div
            style={{
              ...theme.accordeon,
              backgroundColor: theme.accordeon?.backgroundColor,
              paddingBottom: "3rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
            id="info"
          >
            <Box>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {concert?.modalTitle
                  ? concert?.modalTitle
                  : `Welcome to ${concert?.user?.username}’s concert!`}
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {concert?.modalBody
                  ? concert?.modalBody
                  : concert?.hasFeatureDisplayCurrentSong === false &&
                    concert?.hasFeatureLyricsBroadcast === false
                  ? `Open the toggles to learn more about each song. Use the blue buttons below to support ${concert?.user?.username}. \nEnjoy the concert!`
                  : `Tap “View All Songs” to toggle between the current song and the entire setlist.`}
              </Typography>
              {/* <Button
                sx={{ mt: "2rem", mb: "1rem" }}
                onClick={handleModalClose}
                variant="contained"
              >
                GOT IT
              </Button> */}
            </Box>
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default Info;
