import { MAILING_LIST_POKE_FOREVER_NUMBER } from "../constants/frontAppConstants";

export const mailingModalLocalStorageOnConcertLoad = (concert, setMailingListModalOpen) => {
  console.log("executing mailingModalLocalStorageOnConcertLoad function 🏡");
  const userId = concert.user._id;
  const mailingModalStorageKey = `cicada_mailing_modal_${userId}`;

        
  const oneWeekInMilliseconds = 604800000; // 7 days * 24 hours * 60 minutes * 60 seconds * 1000 milliseconds

  const mailingModalStorageItem = JSON.parse(
    localStorage.getItem(mailingModalStorageKey)
  );

  if(mailingModalStorageItem?.signedToMailingList) {
    setMailingListModalOpen(false);
    return;
  }
  
  if(mailingModalStorageItem && mailingModalStorageItem.signedToMailingList === undefined) {
    localStorage.setItem(
      mailingModalStorageKey,
      JSON.stringify({
        ...mailingModalStorageItem,
        signedToMailingList: false,
      })
    );
  }
    
  // Reinitialize local storage item if a week passed since last concert and the audiant didnt gigned in to the artists mailinglist
  if (
    mailingModalStorageItem &&
    mailingModalStorageItem.timestamp < Date.now() - oneWeekInMilliseconds // Check if a week has passed since first mailing list poke
  ) {
    localStorage.setItem(
      mailingModalStorageKey,
      JSON.stringify({
        ...mailingModalStorageItem,
        timestamp: Date.now(),
        timesShown: 1,
      })
      );
  };
      
  
  if (
    concert.mailingListModal?.timesToAutoOpenInSingleBrowser >=
    MAILING_LIST_POKE_FOREVER_NUMBER
  ) {
    setMailingListModalOpen(false);
  } else if (
    !mailingModalStorageItem &&
    concert.mailingListModal?.autoOpen &&
    concert.mailingListModal?.timesToAutoOpenInSingleBrowser < MAILING_LIST_POKE_FOREVER_NUMBER
  ) {
    setMailingListModalOpen(true);
    localStorage.setItem(
      mailingModalStorageKey,
      JSON.stringify({
        timestamp: Date.now(),
        timesShown: 1,
        signedToMailingList: false
      })
    );
    // debugger;
  } else if (
    mailingModalStorageItem &&
    concert.mailingListModal?.autoOpen &&
    concert.mailingListModal?.timesToAutoOpenInSingleBrowser < MAILING_LIST_POKE_FOREVER_NUMBER &&
    mailingModalStorageItem.timesShown < concert.mailingListModal?.timesToAutoOpenInSingleBrowser
  ) {
    setMailingListModalOpen(true);
    localStorage.setItem(
      mailingModalStorageKey,
      JSON.stringify({
        ...mailingModalStorageItem,
        timesShown: mailingModalStorageItem.timesShown+=1,
      })
    );
  } else {
    setMailingListModalOpen(false);
  }
}


export const mailingModalLocalStorageOnFormSubmission = (userId) => {

  console.log("executing mailingModalLocalStorageOnFormSubmission function 🏡");

  const mailingModalStorageKey = `cicada_mailing_modal_${userId}`;

  const mailingModalStorageItem = JSON.parse(
    localStorage.getItem(mailingModalStorageKey)
  );
  
  localStorage.setItem(
    mailingModalStorageKey,
    JSON.stringify({
      ...mailingModalStorageItem,
      signedToMailingList: true
    })
  );
}