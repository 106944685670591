import { ButtonGroup, Button ,Stack } from "@mui/material";
import React from "react";


import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';


function AdminScrollFooter({ arrowDownClick, arrowUpClick }) {
  return (
      <Stack direction="row">
        <ButtonGroup
          variant='contained'
          color='primary'
          aria-label='alignment button group'
        >
          <Button sx={{padding: '2rem 4rem'}} onClick={arrowDownClick}><SouthIcon sx={{fontSize: "2.5rem"}}/></Button>
          <Button sx={{padding: '2rem 4rem'}} onClick={arrowUpClick}><NorthIcon sx={{fontSize: "2.5rem"}}/></Button>
        </ButtonGroup>
      </Stack>
  
  );
}

export default AdminScrollFooter;
