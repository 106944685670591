import React, { useState, useEffect, useContext } from "react";
import Header from "../../components/header/Header";
import ConcertCards from "../../components/concertCards/ConcertCards";
import Sidebar from "../../components/sidebar/Sidebar";
import TopBar from "../../components/topbar/TopBar";

import classes from "./Home.module.css";

import axios from "axios";
import { useLocation } from "react-router";
import { PATH_SUFFIX } from "../../constants/frontAppConstants";

import { Context } from "../../context/Context";

const Home = () => {
  const [concerts, setConcerts] = useState([]);
  const [artistsConcerts, setArtistsConcerts] = useState(null);
  const location = useLocation();

  const { dispatch } = useContext(Context);

  useEffect(() => {
    dispatch({ type: "UPDATE_SCROLLER", payload: false });
  }, []);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    const fetchConcerts = async () => {
      try {
        // const res = await axios.get(`/concerts/${location.search}`);
        const res = await axios.get(`${PATH_SUFFIX}/concerts`);
        setConcerts(res.data.data);
        console.log(res.data.data);

        // location.search === "" ||
        //   (/\?category=/.test(location.search) && setArtistsConcerts(null));

        // /\?user=/.test(location.search) &&
        //   res.data[0] &&
        //   setArtistsConcerts(res.data[0].userName);
      } catch (err) {
        if (axios.isCancel(err)) {
          console.log(`request to get all concerts was canceled`);
        } else {
          console.log(err);
        }
      }
    };

    fetchConcerts();

    return () => {
      cancelToken.cancel();
    };
    // }, [location.search]);
  }, []);

  return (
    <div
      style={{
        color: "black",
        backgroundColor: "azure",
        // backgroundColor: "white",
        // backgroundColor: "rgb(206, 222, 223)",
      }}
    >
      <TopBar />
      <Header />
      <div className={classes.home}>
        <main className={classes.main}>
          <span className={classes.homeTitle}>Upcoming Concerts</span>
          <ConcertCards concerts={concerts} />
        </main>
        {/* <Sidebar /> */}
      </div>
    </div>
  );
};

export default Home;
