import React, { useContext, useState } from "react";
import classes from "./Settings.module.css";
import Sidebar from "../../components/sidebar/Sidebar";
import { Context } from "../../context/Context";

import axios from "axios";

const Settings = () => {
  const { user, dispatch, errorMessage, err } = useContext(Context);

  console.log({ user });

  const [file, setFile] = useState(null);
  const [userName, setUserName] = useState(user.userName);
  const [email, setEmail] = useState(user.email);
  const [password, setPassword] = useState("");
  const [updateSuccess, setUpdateSuccess] = useState(false);

  const PUBLIC_FOLDER = "http://localhost:5000/images/";

  const submitHandler = async (e) => {
    e.preventDefault();
    dispatch({ type: "UPDATE_START" });
    const updatedUser = {
      userId: user._id,
      userName,
      email,
      password,
      updatedAt: user.updatedAt,
    };
    if (file) {
      const data = new FormData();
      console.log(data);
      const filename = Date.now() + file.name;
      data.append("name", filename);
      data.append("file", file);
      // console.log({ photoData: data });
      updatedUser.profilePic = filename;
      try {
        await axios.post("/upload", data);
      } catch (err) {
        console.log("Something went wrong... Couldn't upload the photo. ", {
          err,
        });
        alert("Something went wrong... Couldn't upload the photo. ");
      }
    } else {
      updatedUser.profilePic = user.profilePic;
    }
    try {
      const res = await axios.put(`/users/${user._id}`, updatedUser);

      // Dispatching the user and adding it to the contextAPI data stracture
      // dispatch("LOGIN_SUCCESS", { updatedUser });
      setUpdateSuccess(true);
      console.log(res.data);
      dispatch({ type: "UPDATE_SUCCESS", payload: res.data });

      // window.location.reload();
    } catch (err) {
      console.log({ err });
      err.response.data
        ? dispatch({ type: "UPDATE_FAILURE", payload: err.response.data })
        : dispatch({ type: "UPDATE_FAILURE", payload: "something went wrong" });
    }
  };

  return (
    <div className={classes.settings}>
      <div className={classes.settingsWrapper}>
        <div className={classes.settingsTitle}>
          <span className={classes.settingsUpdateTitle}>Update Account</span>
          <span className={classes.settingsDeleteTitle}>Delete Account</span>
        </div>
        <form className={classes.settingsForm}>
          <label>Profile Picture</label>
          <div className={classes.settingsPP}>
            <img
              // src="https://scontent.ftlv2-1.fna.fbcdn.net/v/t1.6435-9/70990826_2958794167480752_4335528816819568640_n.jpg?_nc_cat=106&ccb=1-5&_nc_sid=8bfeb9&_nc_ohc=OmHjicTcDa8AX-9BaDB&_nc_ht=scontent.ftlv2-1.fna&oh=add9cd6919c4b0eec7949356f9928226&oe=61A71788"
              // src="https://images.pexels.com/photos/4321501/pexels-photo-4321501.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
              // src={file ? URL.createObjectURL(file) : user.profilePic}
              src={
                file
                  ? URL.createObjectURL(file)
                  : PUBLIC_FOLDER + user.profilePic
              }
              alt="pic wasn't upload"
            />
            <label htmlFor="fileInput">
              {/* <i className={`${classes.settingsPPIcon} fas fa-user`}></i> */}
              <i className={`${classes.settingsPPIcon} fas fa-file-upload`}></i>
              {/* <i className={`${classes.settingsPPIcon} far fa-user-circle`}></i> */}
            </label>
            <input
              type="file"
              id="fileInput"
              style={{ display: "none" }}
              onChange={(e) => setFile(e.target.files[0])}
            />
          </div>
          <label>Username</label>
          <input
            type="text"
            placeholder="Username.."
            onChange={(e) => setUserName(e.target.value)}
            value={userName}
          />
          <label>Email</label>
          <input
            type="email"
            placeholder="user@gmail.com.."
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
          <label>Password</label>
          <input
            type="password"
            placeholder="********"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
          <button className={classes.settingsSubmit} onClick={submitHandler}>
            Update
          </button>
          {updateSuccess && (
            <span className={classes.updateSuccess}>
              Profile has been updated...
            </span>
          )}
          {err && <span className={classes.updateSuccess}>{errorMessage}</span>}
        </form>
      </div>
      <Sidebar />
    </div>
  );
};

export default Settings;
