import { Box } from "@mui/material";
import React from "react";
import Button from "../UI/Button";
import classes from "./LyricsFooter.module.css";

const arrowUpStyle = {
  transform: "rotate(270deg)",
  WebkitTransform: "rotate(270deg)" /*  for IE  */,
  /* 	for browsers supporting webkit (such as chrome, firefox, safari etc.). */
  WebkitTransform: "rotate(270deg)",
  display: "inline-block",
};

const btnLeftStyle = {
  borderTopLeftRadius: "10px",
  borderTopRightRadius: "0",
  borderBottomLeftRadius: "10px",
  borderBottomRightRadius: "0",
  border: "solid #3a3cc4",
  // backgroundColor: " #5673B3",
  borderWidth: "3px 1px 3px 3px",
};

const arroWDownStyle = {
  transform: "rotate(90deg)",
  msTransform: "rotate(90deg)" /*  for IE  */,
  /* 	for browsers supporting webkit (such as chrome, firefox, safari etc.). */
  WebkitTransform: "rotate(90deg)",
  display: "inline-block",
};

const btnRightStyle = {
  borderTopLeftRadius: "0",
  borderTopRightRadius: "10px",
  borderBottomLeftRadius: "0",
  borderBottomRightRadius: "10px",
  border: "solid #3a3cc4",
  // backgroundColor: " #5673B3",
  borderWidth: "3px 3px 3px 1px",
};

const arrowButtonStyle = { width: "3.5rem", fontSize: "2.5rem" };

function AdminScrollFooter({ arrowDownClick, arrowUpClick }) {
  return (
    <Box
      sx={{
        textAlign: "center",
        fontFamily: `"Varela Round", sans-serif`,
        borderRadius: "5px",
        padding: "1rem",
        // backgroundColor: "#1f2680",
        backgroundColor: "#323232",
        margin: "1rem",
      }}
    >
      {/* <div className={classes.lyricsFooter} style={lyricsFooterstyle}> */}
      <div className={classes.buttonContainer}>
        <Button
          btnStyles={btnLeftStyle}
          className={`${classes.arrowButton}`}
          onClick={arrowDownClick}
        >
          <span style={{ ...arroWDownStyle, ...arrowButtonStyle }}>
            &#8594;
          </span>
        </Button>
        <Button
          btnStyles={btnRightStyle}
          className={classes.arrowButton}
          onClick={arrowUpClick}
        >
          <span style={{ ...arrowUpStyle, ...arrowButtonStyle }}>&#8594;</span>
        </Button>
      </div>
      {/* </div> */}
    </Box>
  );
}

export default AdminScrollFooter;
