import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Context,
  orderTypes,
  purchaseStageOptions,
} from "../../context/Context";
import { PATH_SUFFIX } from "../../constants/frontAppConstants";
import axios from "axios";

import { Box, Typography, TextField, Stack } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import Button from "@mui/material/Button";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import CustomAlert from "../CustomAlert/CustomAlert";

const Tips = ({ concert, boxHeight }) => {
  const {
    theme,
    dispatch,
    orderType,
    errorMessage: errorMessageServer,
  } = useContext(Context);

  const numberInputStyles = {
    // "& input[type=number]": {
    //   "-moz-appearance": "textfield",
    // },
    // "& input[type=number]::-webkit-outer-spin-button": {
    //   "-webkit-appearance": "none",
    //   margin: 0,
    // },
    // "& input[type=number]::-webkit-inner-spin-button": {
    //   "-webkit-appearance": "none",
    //   margin: 0,
    // },
  };

  const inputLableStyle = {
    fontSize: {
      xs: "1.1rem",
      sm: "1.2rem",
      md: "1.3rem",
      xl: "1.4rem",
    },
  };

  const formLabelStyle = {
    fontSize: {
      xs: "1.3rem",
      sm: "1.5rem",
      md: "1.6rem",
      xl: "1.7rem",
    },
    // marginBottom: "1rem",
    // marginTop: "1rem",

    marginTop: {
      xs: "1.2rem",
    },
    marginBottom: {
      xs: "1rem",
    },
    lineHeight: "2rem",
    fontFamily: `"Varela Round", sans-serif`,
    ...theme.accordeon
    // color: "azure",
    // ...theme.footer,
  };

  const tipButtonStyle = {
    bgcolor: "rgb(48, 48, 71)",
    // marginRight: "0.5rem",
    padding: 0,
    fontSize: "1rem",
  };

  const TIP_AMOUNT_IN_DOLLARS = {
    tip_5: 5,
    tip_20: 20,
    tip_50: 50,
  };


  const [helperText, setHelperText] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [ isTipAmountValid, setIsTipAmountValid ] = useState(false)

  const [error, setError] = useState(false)

  const emailRef = useRef();
  const tipInputRef = useRef();

  const removeAlertHandler = () => setShowAlert(false);

  const handleFixedAmountBtnClick = (event, value) => {
    tipInputRef.current.value = value;
    setShowAlert(false);
    setHelperText("")
    setIsTipAmountValid(true)
  };

  const handleCustomAmountChange = (event) => {
    if (
      (tipInputRef.current.value > 200 || tipInputRef.current.value < 1) &&
      tipInputRef.current.value !== ""
    ) {
      setIsTipAmountValid(false)
      setHelperText("Tip amount only between 1 to 200 USD are allowed");
    } else {
      setHelperText("");
      setIsTipAmountValid(true)
    }
  };

  // const handleRadioChange = (event, value) => {
  //   setValue(event.target.value);
  //   setHelperText("");
  //   setError(false);

  //   if (value === tipAmountInUsDollars.TIP_5) {
  //     dispatch({ type: "UPDATE_ORDER_TYPE", payload: orderTypes.DONATION });
  //     setHelperText(`You choose to donate ${tipAmountInUsDollars.TIP_5} $`);
  //     setError(false);
  //   } else if (value === tipAmountInUsDollars.TIP_20) {
  //     dispatch({ type: "UPDATE_ORDER_TYPE", payload: orderTypes.DONATION });
  //     setHelperText(`You choose to donate ${tipAmountInUsDollars.TIP_20} $`);
  //     setError(false);
  //   } else if (value === tipAmountInUsDollars.TIP_50) {
  //     dispatch({ type: "UPDATE_ORDER_TYPE", payload: orderTypes.DONATION });
  //     setHelperText(`You choose to donate ${tipAmountInUsDollars.TIP_50} $`);
  //     setError(false);
  //   } else {
  //     setHelperText("Please select the amount you would like to donate.");
  //     setError(true);
  //     return;
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Handeling the The submition of the lead
    if (isTipAmountValid) {
      setShowAlert(false);
      dispatch({ type: "SUBMIT_TIP_START" });

      try {
        const res = await axios.post(PATH_SUFFIX + "/payments/stripeTipCheckout", {
          // email: emailRef.current.value,
          concertId: concert._id,
          tipAmountInUSD: tipInputRef.current.value
        });
        dispatch({
          type: "SUBMIT_TIP_SUCCESS",
        });

        window.location = res.data.url

      } catch (err) {
        setError(true)
        err.response.data.error
          ? dispatch({
              type: "SUBMIT_TIP_FAILURE",
              payload: err.response.data.error,
            })
          : dispatch({
              type: "SUBMIT_TIP_FAILURE",
              payload: "something went wrong...",
            });

        setShowAlert(true);
      }
    } else {
      console.log("Form is invalid");
    }
  };

  const artistName = concert.user ? concert.user.username : "the artist";

  console.log(helperText);

  return (
    <Box sx={{ height: "100vh", position: "relative",  }}>
      <Box
        aria-label="tips-pannel"
        id="concert-tips"
        style={{
          height: "100%",
          height: boxHeight,
          ...theme.accordeon,
          // backgroundColor: "rgb(107, 113, 121)",
          padding: "1rem",
          paddingBottom: "0",
          textAlign: "center",
          position: "relative",
          zIndex: 3,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <form onSubmit={handleSubmit} style={{maxWidth: "400px"}}>
          {showAlert && (
            <CustomAlert
              msg_1={errorMessageServer}
              onRemoveAlert={removeAlertHandler}
              passStyle={{ zIndex: 9, padding: 0 }}
              passStyleMsg1={{
                backgroundColor: "rgb(128, 0, 128, 0.9)",
                // height: "100%",
                // width: "100%",
                lineHeight: "2rem",
                fontSize: "1rem",
                "p": {
                  fontSize: "1rem",
                }
              }}
            />
          )}
          <FormControl
            sx={{ m: 3, textAlign: "left" }}
            error={error}
            variant="standard"
          >
            <FormLabel id="demo-error-radios">
              {/* TODO: make better solution!!! */}
              {/* <Typography sx={formLabelStyle}>Tip {artistName}!</Typography> */}
              <Typography sx={formLabelStyle}>Donate to {artistName}!</Typography>
            </FormLabel>


            <Typography
              sx={{
                m: "0.7rem 0 0.5rem 0",
              }}
            >
              Custom amount
            </Typography>

            <TextField
              name="custom-tip"
              size="small"
              type="number"
              // required
              onChange={handleCustomAmountChange}
              inputRef={tipInputRef}
              placeholder="Custom amount in $ (USD)"
              InputProps={{ inputProps: { min: 1, max: 200 } }}
              sx={{
                border: "none",
                bgcolor: "rgb(206, 206, 206)",
                borderRadius: "5px",
                label: {
                  fontSize: "1rem",
                },
                "& input::placeholder": {
                  fontSize: "1rem",
                },
                mb: "1rem",
                ...numberInputStyles,
              }}
            />

            <Stack direction={"row"} sx={{justifyContent: "space-between"}}>
              <Button
                variant="contained"
                sx={tipButtonStyle}
                onClick={(event) =>
                  handleFixedAmountBtnClick(event, TIP_AMOUNT_IN_DOLLARS.tip_5)
                }
              >
                ${TIP_AMOUNT_IN_DOLLARS.tip_5}
              </Button>
              <Button
                variant="contained"
                sx={tipButtonStyle}
                onClick={(event) =>
                  handleFixedAmountBtnClick(event, TIP_AMOUNT_IN_DOLLARS.tip_20)
                }
              >
                ${TIP_AMOUNT_IN_DOLLARS.tip_20}
              </Button>
              <Button
                variant="contained"
                sx={tipButtonStyle}
                onClick={(event) =>
                  handleFixedAmountBtnClick(event, TIP_AMOUNT_IN_DOLLARS.tip_50)
                }
              >
                ${TIP_AMOUNT_IN_DOLLARS.tip_50}
              </Button>
            </Stack>

            

            {/* <Typography
              sx={{
                textAlign: "left",
                mb: "0.5rem",
              }}
            >
              Email
            </Typography>
            <TextField
              size="small"
              type="email"
              required
              inputRef={emailRef}
              placeholder="Enter your email..."
              sx={{
                bgcolor: "azure",
                borderRadius: "5px",
                label: {
                  fontSize: "1rem",
                },
                "& input::placeholder": {
                  fontSize: "1rem",
                },
              }}
            /> */}

            <Button
              // sx={{ margin: "1rem 0", mr: 1 }}
              sx={{ margin: "1rem 0 0 0",
              "&:disabled": {
                // color: "rgb(54, 54, 54)",
                color: theme.modeName === "dark" && "rgb(187, 187, 187)",
                border: theme.modeName === "dark" && "0.01rem solid rgb(22, 22, 22)",
              }
            }}
              type="submit"
              variant="contained"
              disabled={!isTipAmountValid}
            >
              Submit
            </Button>
            <Typography
              sx={{
                color: "rgb(167, 15, 53)",
                m: "1rem 0",
                fontSize: "1.1rem",
                fontWeight: "600",
                // textAlign: "center",
              }}
            >
              {helperText}
            </Typography>

            <Stack direction={"row"}>

              <HelpOutlineIcon sx={{ fontSize: "0.9rem", m: "0.1rem"}} /> 
              <Typography sx={{ fontSize: "0.8rem" }}>
                Credit card details are never stored on our servers. Payment data is handled by Stripe.
              </Typography>
            </Stack>
          </FormControl>


        </form>
      </Box>
    </Box>
  );
};

export default Tips;
