import React from "react";
import classes from "./LyricsFooter.module.css";
import { Box } from "@mui/system";
import { Button, Stack, TextField } from "@mui/material";

const EmailForm = React.forwardRef((props, ref) => {
  
  const { emailFormLabel } =  props 
  
  return (
    <Box
      sx={{
        textAlign: "center",
        fontFamily: `"Varela Round", sans-serif`,
        borderRadius: "5px",
        padding: "1rem",
        // backgroundColor: "#1f2680",
        backgroundColor: "#323232",
        margin: "1rem",
      }}
    >
      <div className={classes.merchContainer}>
        <form className={classes.emailForm} onSubmit={props.handleSubmit}>
          <label htmlFor="email" style={{color: 'azure'}}>
            {emailFormLabel}
          </label>
          <div
            className={classes.innerFlexContainer}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <Stack spacing={0.5} direction="row">
              <TextField
                size="small"
                type="email"
                required
                inputRef={ref}
                placeholder="Enter your email..."
                sx={{
                  bgcolor: "azure",
                  borderRadius: "5px",
                  label: {
                    fontSize: "1rem",
                  },
                  "& input::placeholder": {
                    fontSize: "0.9rem"
                  }
                }}
              />
              <Button
                type="submit"
                disabled={props.disabled}
                variant="contained"
                sx={{
                  fontfamily: `"Varela Round", sans-serif`,
                  fontSize: "0.95rem",
                }}
              >
                Submit
              </Button>
            </Stack>
          </div>
        </form>
      </div>
    </Box>
  );
});

export default EmailForm;
