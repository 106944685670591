import React from "react";
// import Button from "../UI/Button";
import {
  Stack,
  Button,
  ButtonGroup,
  Box,
} from "@mui/material";
import classes from "./LyricsFooter.module.css";

const buttonGroupTitle = {
  fontSize: "1.4rem",
  lineHeight: "2rem",
  fontFamily: `"Varela Round", sans-serif`,
  color: "azure",
};

const MerchOrDonation = ({ handleMerch, handleDonation }) => {
  return (
    <Box
      sx={{
        textAlign: "center",
        fontFamily: `"Varela Round", sans-serif`,
        borderRadius: "5px",
        padding: "1rem",
        // backgroundColor: "#1f2680",
        // backgroundColor: "#dcdcdc30",
        backgroundColor: "#323232",
        margin: "1rem",
      }}
    >
      <Stack spacing={2} direction="column">
        <p style={buttonGroupTitle}>Support the Artist!</p>

        <ButtonGroup
          variant="contained"
          orientation="horizontal"
          color="primary"
          aria-label="alignment button group"
        >
          <Button
            onClick={handleMerch}
            sx={{
              fontFamily: `"Varela Round", sans-serif`,
              // bgcolor: "#3a3cc4",
              // backgroundColor: "#1f2680",
              backgroundColor: " #5673B3",
              "&:hover": {
                bgcolor: "#3a3cc4",
              },
              padding: "10px",
            }}
          >
            Buy Merch
          </Button>
          <Button
            onClick={handleDonation}
            sx={{
              fontFamily: `"Varela Round", sans-serif`,
              // bgcolor: "#3a3cc4",
              // backgroundColor: "#1f2680",
              backgroundColor: " #5673B3",
              "&:hover": {
                bgcolor: "#3a3cc4",
              },

              padding: "10px",
            }}
          >
            Give donation
          </Button>
        </ButtonGroup>
      </Stack>
    </Box>
  );
};

export default MerchOrDonation;
