import React, { useContext } from "react";
import { Context } from "../../context/Context";
import classes from "./Lyrics_V2.module.css";

const Lyrics_V2 = ({ songId, lyrics, isFocused }) => {
  const { theme, concertLyricsStyles } = useContext(Context);

  // console.log("{ concertLyricsStyles }");
  // console.log({ concertLyricsStyles });

  const songGlobalStyles = concertLyricsStyles.songs ? concertLyricsStyles.songs[songId] : null;
  const songsGlobalPFontSize = songGlobalStyles?.pFontSize || null;
  const songsGlobalAFontSize = songGlobalStyles?.aFontSize || null;

  const adminsFocusedStyle = {
    dark: {
      // color: isFocused && "rgb(97, 134, 202)",
      color: isFocused && "#b86f6f",
      fontWeight: isFocused && 900,
      textShadow: isFocused && "2px 2px 4px #333",
    },
    light: {
      color: isFocused && "rgb(88, 120, 180)",
      fontWeight: isFocused && 900,
      textShadow: isFocused && "2px 2px 4px #e0e0e0",
    },
  };

  const fixedText = lyrics.replace(/\n\r|\r\n|\r/g, "\n");
  const lines = fixedText.split("\n");

  // let pFontSize = "";
  // let aFontSize = "";

  // const linesModified = lines
  //   .map((line, index) => {
  //     const pMatch = line.match(/^P_FONT_SIZE=(.*?);?$/m);
  //     const aMatch = line.match(/^A_FONT_SIZE=(.*?);?$/m);
  //     if (pMatch) {
  //       pFontSize = pMatch[1];
  //     } else if (aMatch) {
  //       aFontSize = aMatch[1];
  //     } else {
  //       return line;
  //     }
  //   })
  //   .filter((line) => line !== undefined);

  const linesModified = lines
    .filter((line) => {
      const pMatch = line.match(/^P_FONT_SIZE=(.*?);?$/m);
      const aMatch = line.match(/^A_FONT_SIZE=(.*?);?$/m);
      return !pMatch && !aMatch
    })
    .filter((line) => line !== undefined);

  function getUpdatedHref(href) {
    if (!href.startsWith("http://") && !href.startsWith("https://")) {
      return "https://" + href;
    } else if (href.startsWith("http://")) {
      return "#";
    } else {
      return href;
    }
  }

  return (
    <span
      style={
        theme.modeName === "dark"
          ? adminsFocusedStyle.dark
          : adminsFocusedStyle.light
      }
    >
      {linesModified.map((line, index) => {
        let className = "";
        if (line.length === 0) {
          return <br key={index} />;
        }
        const announceMatch = line.match(/^.*<announce>(.*?)<\/announce>.*$/m);
        const customLinkMatch = line.match(
          /^.*<CustomLink\s+href="(.*?)">(.*?)<\/CustomLink>.*$/m
        );
        if (announceMatch) {
          line = line.replace("<announce>", "");
          line = line.replace("</announce>", "");
          return (
            <p
              key={index}
              style={{
                margin: "2.5rem  0 1.4rem 0",
                border: "5px solid #8597e7",
                borderRadius: "5px",
                padding: "7px",
                // fontSize: pFontSize ? pFontSize : "1.7rem",
                fontSize: songsGlobalPFontSize
                  ? songsGlobalPFontSize
                  : "1.7rem",
              }}
            >
              {line}
            </p>
          );
        }
        if (customLinkMatch) {
          const href = customLinkMatch[1];
          const text = customLinkMatch[2];
          const updatedHref = getUpdatedHref(href);

          return (
            <a
              key={index}
              href={updatedHref}
              style={{
                // textDecoration:"none",
                color: theme.a.color,
                display: "block",
                textAlign: "left",
                margin: "2.5rem  0 1.4rem 0",
                padding: "7px",
                // fontSize: aFontSize ? aFontSize : "1.7rem",
                fontSize: songsGlobalAFontSize
                  ? songsGlobalAFontSize
                  : "1.7rem",
              }}
              target="_blank"
            >
              {text}
            </a>
          );
        }
        return (
          <p
            style={{
              // fontSize: pFontSize ? pFontSize : "1.7rem",
              fontSize: songsGlobalPFontSize ? songsGlobalPFontSize : "1.7rem",
            }}
            className={classes[className]}
            key={index}
          >
            {line}
          </p>
        );
      })}
    </span>
  );
};

export default Lyrics_V2;
