import React from "react";
import classes from "./LyricsFooter.module.css";
import { Box } from "@mui/system";
import { Button, Stack, TextField } from "@mui/material";

const EmailForm = React.forwardRef((props, ref) => {
  const { emailFormLabel } = props;

  return (
    <Box
      sx={{
        textAlign: "center",
        fontFamily: `"Varela Round", sans-serif`,
        borderRadius: "5px",
        padding: "1rem",
        margin: "1rem",
      }}
    >
      <form className={classes.emailForm} onSubmit={props.handleSubmit}>
        <label htmlFor="email" style={{ color: "azure" }}>
          {emailFormLabel}
        </label>

        <Stack
          spacing={0.5}
          direction="row"
          sx={{ alignItems: "center", justifyContent: "center" }}
        >
          <TextField
            size="small"
            type="email"
            required
            inputRef={ref}
            placeholder="Enter your email..."
            sx={{
              bgcolor: "azure",
              borderRadius: "5px",
              label: {
                fontSize: "1rem",
              },
              "& input::placeholder": {
                fontSize: "1rem",
              },
            }}
          />
          <Button
            type="submit"
            disabled={props.disabled}
            variant="contained"
            sx={{
              fontfamily: `"Varela Round", sans-serif`,
              fontSize: "1rem",
            }}
          >
            Submit
          </Button>
        </Stack>
      </form>
    </Box>
  );
});

export default EmailForm;
