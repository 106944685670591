import React from 'react';
import ReactDOM from 'react-dom/client';
import App from "./App.jsx";
import { ContextProvider, Context } from "./context/Context";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<React.StrictMode>
  <ContextProvider>
     <App />
   </ContextProvider>
  </React.StrictMode>
);
