import React from "react";
// import './Switch.css';
import classes from "./Switch.module.css";

const Switch = ({ isOn, handleToggle, onColor, text }) => {
  return (
    <div className={classes.container}>
      <span className={classes.text}>{text}</span>
      <input
        checked={isOn}
        onChange={handleToggle}
        className={`${classes["react-switch-checkbox"]}`}
        id={`react-switch-new`}
        type="checkbox"
      />
      <label
        style={{ background: isOn && onColor }}
        className={classes["react-switch-label"]}
        htmlFor={`react-switch-new`}
      >
        <span className={`${classes["react-switch-button"]}`} />
      </label>
    </div>
  );
};

export default Switch;
