import React, { useContext, useEffect, useRef, useState } from "react";
// import classes from "./LyricsFooter.module.css";
import axios from "axios";
import { useLocation } from "react-router-dom";

import { Context } from "../../context/Context";
import { purchaseStageOptions, orderTypes } from "../../context/Context";
import { PATH_SUFFIX } from "../../constants/frontAppConstants";

import AdminScrollFooter from "./AdminScrollFooter";
import AdminNonScrollFooter from "./AdminNonScrollFooter";
import EmailForm from "./EmailForm";
import MerchOrDonation from "./MerchOrDonation";
import ThankyouLyricsFooter from "./ThankyouLyricsFooter";
import CustomAlert from "../CustomAlert/CustomAlert";
import { Box } from "@mui/material";

const LyricsFooter = ({ arrowUpClick, arrowDownClick, concert, isCameraOn }) => {
  const location = useLocation();
  const concertId = location.pathname.split("/")[2];

  const { isScroller, isConcertAdmin, dispatch, errorMessage, purchaseStage, isFetching, orderType } =
    useContext(Context);

  const { footerButton1, footerButton2 } = concert.audienceAction || { footerButton1: null, footerButton2: null };

  const [showAlert, setShowAlert] = useState(false);
  const [emailFormLabel, setEmailFormLabel] = useState(
    "Enter your email to continue."
  );
  const emailRef = useRef();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowAlert(false);
    dispatch({ type: "SUBMIT_LEAD_START" });

    try {
      const res = await axios.post(PATH_SUFFIX + "/leads", {
        email: emailRef.current.value,
        concertId,
        orderType,
      });
      dispatch({
        type: "SUBMIT_LEAD_SUCCESS",
        payload: purchaseStageOptions.THANK_YOU_POPUP,
      });

      console.log(orderType, orderTypes.DONATION, concert.donationUrl);
      console.log(orderType, orderTypes.DONATION, concert.merchUrl);

      if(orderType === orderTypes.DONATION && concert.donationUrl) {
        window.location.href = concert.donationUrl;
      } else if(orderType === orderTypes.MERCH && concert.merchUrl) {
        window.location.href = concert.merchUrl;
      } else {
        console.log("Thank you for your support!")
      }
      
    } catch (err) {
      err.response.data.error
        ? dispatch({
            type: "SUBMIT_LEAD_FAILURE",
            payload: err.response.data.error,
          })
        : dispatch({
            type: "SUBMIT_LEAD_FAILURE",
            payload: "something went wrong...",
          });

      console.error(err.response.data.error);
      setShowAlert(true);
    }
  };

  const removeAlertHandler = () => setShowAlert(false);

  const handleMerch = () => {
    dispatch({
      type: "UPDATE_PURCHASE_STAGE",
      payload: purchaseStageOptions.GIVE_EMAIL,
    });
    dispatch({ type: "UPDATE_ORDER_TYPE", payload: orderTypes.MERCH });
    setEmailFormLabel(
      "Enter your email to continue."
    );
  };
  const handleDonation = () => {
    dispatch({
      type: "UPDATE_PURCHASE_STAGE",
      payload: purchaseStageOptions.GIVE_EMAIL,
    });
    dispatch({ type: "UPDATE_ORDER_TYPE", payload: orderTypes.DONATION });
    setEmailFormLabel(
      "Enter your email to begin."
    );
  };

  return (
    <Box >
      {isScroller && isConcertAdmin ? (
        <AdminScrollFooter
          arrowUpClick={arrowUpClick}
          arrowDownClick={arrowDownClick}
        />
      ) : isConcertAdmin && !isScroller ? (
        <AdminNonScrollFooter/>
      )
      : !purchaseStage ? (
        <MerchOrDonation
          hasMerchUrl={!!concert.merchUrl}
          hasDonationUrl={!!concert.donationUrl}
          isCameraOn={isCameraOn}
          handleMerch={handleMerch}
          handleDonation={handleDonation}
          artistName={concert.user ? concert.user.username : "the artist"}
          footerButton1={footerButton1}
          footerButton2={footerButton2}
        />
      ) : purchaseStage === purchaseStageOptions.GIVE_EMAIL && !showAlert ? (
        <EmailForm
          handleSubmit={handleSubmit}
          ref={emailRef}
          disabled={isFetching}
          emailFormLabel={emailFormLabel}
        />
      ) : (
        purchaseStage === purchaseStageOptions.THANK_YOU_POPUP && (
          <ThankyouLyricsFooter />
        )
      )}
      {showAlert && (
        <CustomAlert
          msg_1={errorMessage}
          onRemoveAlert={removeAlertHandler}
          passStyle={{ zIndex: 9, padding: 0 }}
          passStyleMsg1={{
            backgroundColor: "rgb(128, 0, 128, 0.9)",
            height: "100%",
            width: "100%",
            lineHeight: "2rem",
          }}
        />
      )}
    </Box>
  );
};

export default LyricsFooter;
