import React, { useRef } from "react";
import styles from "./Button.module.css";


const Button = (props) => {

  const btnStyles = props.btnStyles || {};

  const classes =
    styles.button + (props.className ? " " + props.className : "");
  // console.log({ classes });
  // console.log({ ctylesButton: styles.button });

  // Chet GPT offered to do this - check it out 
  // const classes = [styles.button, props.className].join(" ").trim();


  return (
    // <button onClick={props.onClick} type={props.type} className={styles.button}>
    <button
      onClick={props.onClick}
      type={props.type}
      className={classes}
      style={btnStyles}
    >
      {props.children}
    </button>
  );
};

export default Button;
