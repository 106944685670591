import React from 'react'
import {
  Box,
  Button,
  Typography,
  Modal,
  IconButton,
  Stack
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

const modalStyle = {
  position: "absolute", 
  top: "35%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "rgb(217, 220, 238)",
  border: "3px solid #1d1d1d",
  borderRadius: "7px",
  boxShadow: 24,
  p: 2,

  width: {
    xs: "100%",
    sm: "100%",
    md: "40%",
    xl: "30%",
  },
  maxWidth: "50%",
  textAlign: "center",
};

const LyricsInfoModal = ({isModalOpen, handleModalClose, concert }) => {
  return (
    <div
        id="modal-div"
        style={{
          margin: "0 2rem",
        }}
      >
        <Modal
          open={isModalOpen}
          onClose={handleModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            <Stack sx={{ flexDirection: "row-reverse", mb: "1rem" }}>
              <IconButton
                onClick={handleModalClose}
                sx={{
                  padding: { xs: "7px" },
                  "&:hover, &:active": {
                    backgroundColor: "rgba(40, 15, 140, 0.2)", // Optional: add a background color for hover and active states
                  },
                }}
              >
                <CloseIcon sx={{ color: "rgb(40, 15, 140)" }} />
              </IconButton>
            </Stack>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {concert?.modalTitle
                ? concert?.modalTitle
                : `Welcome to ${concert?.user?.username}’s concert!`}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {concert?.modalBody
                ? concert?.modalBody
                : concert?.hasFeatureDisplayCurrentSong === false &&
                  concert?.hasFeatureLyricsBroadcast === false
                ? `Open the toggles to learn more about each song. Use the blue buttons below to support ${concert?.user?.username}. \nEnjoy the concert!`
                : `Tap “View All Songs” to toggle between the current song and the entire setlist.`}
            </Typography>
            <Button
              sx={{ mt: "2rem", mb: "1rem" }}
              onClick={handleModalClose}
              variant="contained"
            >
              GOT IT
            </Button>
          </Box>
        </Modal>
      </div>
  )
}

export default LyricsInfoModal