import React, { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router";
import axios from "axios";
import classes from "./SingleConcertAdmin.module.css";
import { Link } from "react-router-dom";
import { Context } from "../../context/Context";
import { PATH_SUFFIX } from "../../constants/frontAppConstants";
import Lyrics from "../lyrics/Lyrics";
import Switch from "../switch/Switch";
import { io } from "socket.io-client";
import LyricsFooter from "../lyricsFootter/LyricsFooter";
import { Box, Divider } from "@mui/material";

const SingleConcertAdmin = () => {
  const location = useLocation();

  const concertId = location.pathname.split("/")[2];

  const [concert, setConcert] = useState({});
  const date = concert ? new Date(concert.startsAt) : null;
  const dateString = date.toDateString();

  const { isScroller, user, dispatch, theme } = useContext(Context);

  const [artist, setArtist] = useState(""); // concert.user.username
  const [title, setTitle] = useState(""); // concert.eventName
  const [messageToAudiance, setMessageToAudiance] = useState(""); // concert.messageToTheAudiance
  const [songs, setSongs] = useState([]); // concert.songs
  const [eventName, setEventName] = useState("");
  const [isDarkMode, setIsDarkMode] = useState(true);

  const [socket, setSocket] = useState();

  const handleToggleDarkMode = () => {
    setIsDarkMode((isDarkMode) => !isDarkMode);
    dispatch({ type: "TOGGLE_THEME" });
  };
  const darkMode = isDarkMode ? classes.darkMode : "";

  const handleActiveScroll = async (event) => {
    if (isScroller === true) {
      console.log({ eventType: event.type });
      let scrollY = 0;
      // scrollY = window.scrollY;
      scrollY = window.pageYOffset;

      await socket.emit("scroll", {
        concertId,
        // position: scrollY,
        position: scrollY / document.body.clientHeight,
      });
      // console.log(window.scrollY);
      console.log(window.pageYOffset);
    }
  };

  const HandleArrowUpClick = () => {
    // window.scroll(0, window.scrollY - 120);
    window.scrollBy({
      top: -300,
      behavior: "smooth",
    });
  };

  const HandleArrowDownClick = () => {
    // window.scroll(0, window.scrollY + 120);
    window.scrollBy({
      top: 300,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    const fetchSingleConcert = async () => {
      try {
        const res = await axios.get(`${PATH_SUFFIX}/concerts/${concertId}`, {
          cancelToken: cancelToken.token,
        });
        setConcert(res.data.data);
        setArtist(res.data.data.user.username);
        setTitle(res.data.data.eventName);
        setMessageToAudiance(res.data.data.messageToAudience);
        setSongs(res.data.data.songs);
        setEventName(res.data.data.eventName);
      } catch (err) {
        if(axios.isCancel(err)) {
          console.log(`request to ${concertId} concert was canceled`)
        } else {
          console.log(err);
        }
      }
    };
    fetchSingleConcert();

    return () => {
      cancelToken.cancel();
    };
  }, [concertId]);

  useEffect(() => {
    const sock = io();
    sock.on("connect", () => {
      setSocket(sock);
    });

    return () => {
      sock.close();
    };
  }, [concertId]);

  const handleStopActiveScroll = () => {
    dispatch({ type: "UPDATE_SCROLLER", payload: false });
  };

  useEffect(() => {
    console.log(socket);
    if(socket?.connected) {
      window.addEventListener("wheel", handleActiveScroll);
      window.addEventListener("keydown", handleActiveScroll);
      window.addEventListener("keyup", handleActiveScroll);
      window.addEventListener("scroll", handleActiveScroll);
      window.addEventListener("touchmove", handleActiveScroll, {
        passive: false,
      });
    }
    return () => {
      window.removeEventListener("wheel", handleActiveScroll);
      window.removeEventListener("keydown", handleActiveScroll);
      window.removeEventListener("keyup", handleActiveScroll);
      window.removeEventListener("scroll", handleActiveScroll);
      window.removeEventListener("touchmove", handleActiveScroll);
    };
  }, [handleActiveScroll, socket?.connected]);

  return (
    // <div
    //   className={`${classes.singleConcert} `}
    // >
    <Box
      sx={{
        // width: "100vw",
        padding: "1rem",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        ...theme.general,
      }}
    >
      <div className={classes.singleConcertWrapper}>
        {/* {concert.photo && (
          <img
          src={PUBLIC_FOLDER + concert.photo}
          alt="Concerts picture"
          className={classes.singleConcertImg}
          />
        )} */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "1rem",
          }}
        >
          <button
            className={classes.activeScrollBtn}
            style={{
              display: isScroller === false && "none",
            }}
            onClick={handleStopActiveScroll}
          >
            STOP Active Scroll
          </button>
          <Switch
            isOn={isDarkMode}
            handleToggle={handleToggleDarkMode}
            onColor="rgb(18, 18, 18)"
            text="Dark / Light Mode"
          />
        </div>
        <div
          className={classes.singleConcertInfo}
          style={{ textAlign: "left" }}
        >
          <span className={classes.singleConcertDate}>
            Concert date: {dateString}
          </span>
          <span className={classes.singleConcertAuthor}>
            Author: <b>{artist}</b>
          </span>
        </div>
        <Divider sx={{ bgcolor: "#879EB8", mb: "2rem", mt: "0.5rem" }} />
        <p
          className={`${classes.singleConcertTitle} `}
          style={{
            // ...theme.general, // NO THEME CHANGE HERE
            fontFamily: `"Josefin Sans", sans-serif`,
            textAlign: "left",
            background: "rgb(70, 70, 70)", // IMPORTANT
            color: "white",
            padding: "1rem",
            borderRadius: "5px",
            fontSize: "1.7rem",
            lineHeight: "2.2rem",
          }}
        >
          {eventName}
        </p>
        {/* <Divider sx={{ bgcolor: "#879EB8", mb: "2rem", mt: "0.5rem" }}/> */}

        <p
          className={`${classes.singleConcertDesc} `}
          style={{
            ...theme.general,
            border: "2px solid #7A49A5",
            borderRadius: "5px",
            padding: "1rem",
            margin: "4rem 0",
            fontSize: "1.7rem",
            lineHeight: "2.2rem",
            fontFamily: `"Varela Round", sans-serif`,
          }}
        >
          {messageToAudiance}
        </p>

        <div className={classes.songsWrapper}>
          {songs &&
            songs.map((song) => {
              if (song.isVisible) {
                return (
                  <div key={song._id} className={classes.songContainer}>
                    <p
                      className={`${classes.songTitle} `}
                      style={{
                        ...theme.general,
                        fontSize: "1.7rem",
                        lineHeight: "2.2rem",
                        margin: "2rem 0",
                      }}
                    >
                      {song.title}
                    </p>
                    {/* TODO: fix that shit! */}
                    <br></br>
                    <br></br>
                    <br></br>
                    <Lyrics lyrics={song.lyrics} />
                    <br></br>
                  </div>
                );
              }
            })}
        </div>
        <LyricsFooter
          isDarkMode={isDarkMode}
          arrowUpClick={HandleArrowUpClick}
          arrowDownClick={HandleArrowDownClick}
        />
      </div>
      {/* </div> */}
    </Box>
  );
};

export default SingleConcertAdmin;
