import React from "react";
import ConcertCard from "../concertCard/ConcertCard";
import classes from "./ConcertCards.module.css";

const ConcertCards = ({ concerts }) => {

  concerts?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  return (
    <div className={classes.postsWrapper}>
      
      <div className={classes.posts}>
        {concerts?.map((concert) => {
          return (
            <ConcertCard
              key={concert._id}
              id={concert._id}
              concert={concert}
            />
          );
        })}

        {/* <Concert
          key="1"
          id="1"
          title="The quick brown fox"
          desc="The quick brown fox jumps over the lazy dog. The rain in Spain falls mainly on the plain..."
          photo="https://www.google.com/url?sa=i&url=https%3A%2F%2Funsplash.com%2Fs%2Fphotos%2Fmusic&psig=AOvVaw2drxt2f43rXHeE6mtNVKZZ&ust=1673243135347000&source=images&cd=vfe&ved=0CA8QjRxqFwoTCKCK5cait_wCFQAAAAAdAAAAABAE"
          userName="My Mege Post"
          categories={["cat1", "cat2", "cat3"]}
          createdAt="Sun Jan 08 2023 08:42:24 GMT+0200 (Israel Standard Time)"
        />
        <Concert
          key="1"
          id="1"
          title="The quick brown fox"
          desc="The quick brown fox jumps over the lazy dog. The rain in Spain falls mainly on the plain./n
          The quick brown fox jumps over the lazy dog. The rain in Spain falls mainly on the plain.
          The quick brown fox jumps over the lazy dog. The rain in Spain falls mainly on the plain.
          The quick brown fox jumps over the lazy dog. The rain in Spain falls mainly on the plain.
          The quick brown fox jumps over the lazy dog. The rain in Spain falls mainly on the plain.
          The quick brown fox jumps over the lazy dog. The rain in Spain falls mainly on the plain."
          photo="https://www.google.com/url?sa=i&url=https%3A%2F%2Funsplash.com%2Fs%2Fphotos%2Fmusic&psig=AOvVaw2drxt2f43rXHeE6mtNVKZZ&ust=1673243135347000&source=images&cd=vfe&ved=0CA8QjRxqFwoTCKCK5cait_wCFQAAAAAdAAAAABAE"
          userName="My Mege Post"
          categories={["cat1", "cat2", "cat3"]}
          createdAt="Sun Jan 08 2023 08:42:24 GMT+0200 (Israel Standard Time)"
        /> */}
      </div>
    </div>
  );
};

export default ConcertCards;
