import React, { useContext, useEffect, useRef, useState } from "react";
import { Context } from "../../context/Context";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  ImageListItem,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Stack } from "@mui/system";
import Lyrics_V2 from "../../components/lyrics_V2/Lyrics_V2";
import VideoStreams from "../videoStreams/VideoStreams";

const ActiveScrollMode = ({
  scrollingWindowRef,
  handleAccordionChange,
  songs,
  expanded: currentSongId,
  lyricsSliceIndex,
  boxHeight,
}) => {
  const { theme, adminMode } = useContext(Context);
  const { isAdminLyricsBroadcastMode } = adminMode;

  const accordionRefs = useRef([]);

  useEffect(() => {
    if (currentSongId && accordionRefs.current[currentSongId]) {
      setTimeout(() => {
        accordionRefs.current[currentSongId]?.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
          alignToTop: true,
        });
      }, 1000);
    }
  }, [currentSongId, accordionRefs.current[currentSongId]]);

  const adminsFocusedLyricsRef = useRef([]);
  useEffect(() => {
    if (isAdminLyricsBroadcastMode && currentSongId) {
      if (lyricsSliceIndex !== 0) {
        const portionId = `${currentSongId}-${lyricsSliceIndex || 0}`;
        adminsFocusedLyricsRef.current[portionId]?.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
          alignToTop: true,
        });
      } else {
        accordionRefs.current[currentSongId]?.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
          alignToTop: true,
        });
      }
    }
  }, [lyricsSliceIndex]);

  return (
    <Box sx={{ height: boxHeight }}>
      <div
        id="scrollingWindow"
        ref={scrollingWindowRef}
        style={{ height: "100%", overflowY : "scroll" }}
      >
        {songs.map((song) => {
          return (
            <Accordion
              sx={{ ...theme.accordeon, textAlign: "center"}}
              key={song._id}
              expanded={currentSongId === song._id}
              onChange={(event, isExpanded) => {
                handleAccordionChange({
                  isExpanded,
                  songId: song._id,
                  title: song.title,
                  videoStreams: song.videoStreams,
                  photo: song.photo,
                  lyrics: song.lyrics,
                  lyricsSliced: song.lyricsSliced,
                });
              }}
            >
              <AccordionSummary
                ref={(ref) => (accordionRefs.current[song._id] = ref)}
                aria-controls={`${song._id}-content`} // Accessability
                id={`${song._id}-header`}
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography sx={{ fontFamily: `"Josefin Sans", sans-serif;`,textAlign: 'left' }}>
                  {song.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                aria-controls={`${song._id}-content`} // Accessability
              >
                <VideoStreams currentSong={song} />
                {song.photo !== "no-photo.jpg" &&
                  song.photo !== "" &&
                  song.photo && (
                    <ImageListItem sx={{ marginBottom: "2rem" }}>
                      <img
                        src={"/song_photo_uploads/" + song.photo}
                        alt={song.title}
                      />
                    </ImageListItem>
                  )}
                <div
                  style={{ ...theme.accordeon, paddingBottom: "3rem" }}
                  id="playlist"
                >
                  {isAdminLyricsBroadcastMode
                    ? // ? song?.lyricsSliced[lyricsSliceIndex]?.map((ln, i) => {
                      //     return <Lyrics_V2 key={i} lyrics={ln} />;
                      //   })
                      // : song && <Lyrics_V2 lyrics={song.lyrics} />
                      song?.lyricsSliced.map((slice, portionIndex) => {
                        const portionId = `${song._id}-${portionIndex}`;
                        return (
                          <div
                            key={portionId}
                            ref={(ref) => {
                              adminsFocusedLyricsRef.current[portionId] = ref;
                            }}
                          >
                            {slice.map((ln, i) => {
                              const lineId = `${song._id}-${portionIndex}-${i}`;
                              return (
                                <Lyrics_V2
                                  key={lineId}
                                  songId={song._id}
                                  lyrics={ln}
                                  isFocused={
                                    portionIndex === lyricsSliceIndex
                                      ? true
                                      : false
                                  }
                                />
                              );
                            })}
                          </div>
                        );
                      })
                    : song && (
                        <Lyrics_V2 songId={song._id} lyrics={song.lyrics} />
                      )}
                </div>
                {/* <Lyrics_V2 lyrics={song.lyrics} /> */}
                <Stack direction={"row"}>
                  <Box sx={{ width: "99%" }}></Box>
                  <IconButton
                    size="large"
                    aria-label="send"
                    sx={{ mt: "1rem" }}
                    onClick={() =>
                      handleAccordionChange({
                        isExpanded: null,
                        songId: "",
                        title: "",
                        videoStreams: [],
                        photo: "",
                        lyrics: "",
                        lyricsSliced: [],
                      })
                    }
                  >
                    <ExpandLessIcon sx={{ fontSize: "2.5rem" }} />
                  </IconButton>
                </Stack>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    </Box>
  );
};

export default ActiveScrollMode;
