import React, { useContext } from "react";
import {
  Stack,
  Button,
  FormControlLabel,
  Switch,
  Typography,
} from "@mui/material";

import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import FlipCameraIosIcon from "@mui/icons-material/FlipCameraIos";
import LinkedCameraIcon from "@mui/icons-material/LinkedCamera";
import NoPhotographyIcon from "@mui/icons-material/NoPhotography";

import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import LightbulbIcon from "@mui/icons-material/Lightbulb";

import logoLightMode from "../../images/WhiteCircleBlackLogo.png";
import logoDarkMode from "../../images/GrayCircleWhiteLogo.png";
import mailingList from "../../images/mailing-list-2.2.png";

import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";

import { Link } from "react-router-dom";
import { Context } from "../../context/Context";

const switchStyle = {
  borderRadius: 2,
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#1565c0",
  },
  "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
    backgroundColor: "lightblue",
  },
  "& .MuiSwitch-switchBase:not(.Mui-checked)+.MuiSwitch-track": {
    backgroundColor: "#ffffff",
  },
  "&:hover .MuiSwitch-switchBase": {
    color: "#3a3cc4",
  },
  ml: "1rem",
};

const label = { inputProps: { "aria-label": "Switch demo" } };

const LyricsTopBar = ({
  themeChecked,
  handleThemeChange,
  handleLyricsBroadcastToggle,
  handleAudiantActiveScrollChange,
  setIsCameraOn,
  concert,
  expanded,
  currentSong,
  songs,
  isCameraOn,
  CAMERA_FACING,
  setCameraFacing,

  handleMailingListModalOpen
}) => {
  const { isScroller, isConcertAdmin, audientIneraction, theme } =
    useContext(Context);

  return (
    <Stack
      id="lyrics-top-bar"
      direction={"column"}
      sx={{
        marginTop: "1rem",
        marginBottom: "0.3rem",

        // position: "fixed",

        // top: 0,
        // left: 0,
        // width: "100%",
        // padding: "20px"
        // overflow: "visible"
      }}
    >
      <Stack
        id="first-button-row"
        p={1}
        direction={"row"}
        sx={{
          justifyContent: "space-between",
          width: "100%",
          height: "0.1rem",
          // position: "sticky",
          // position: "static",
          top: 0,
          bottom: "unset",
          left: 0,
          zIndex: "10",
          bgcolor: theme.general.backgroundColor,
          opacity: 0.9,
          mb: "0.5rem",
          display: "flex",
          alignItems: "center",
        }}
      >
        {/* ConcertsTopBar */}
        <Link className="link" to={`/`}>
          <Button sx={{ padding: { sx: "10px" } }}>
            {/* <HomeIcon sx={{ mt: "7px" }} /> */}
            <img
              src={theme.modeName === "light" ? logoLightMode : logoDarkMode}
              alt="Logo"
              height={"35rem"}
              width={"35rem"}
            />
          </Button>
        </Link>
        {/* <FormControlLabel
          control={
            <Switch
              {...label}
              checked={themeChecked}
              onChange={handleThemeChange}
              sx={{ ...switchStyle }}
            />
          }
          label={
            <Typography
              sx={{
                fontFamily: `"Josefin Sans", sans-serif;`,
                fontSize: {
                  xs: "0.9rem",
                  sm: "1rem",
                },
                lineHeight: "1.1rem",
              }}
            >
              {themeChecked ? "Dark mode" : "light mode"}
            </Typography>
          }
        /> */}

        {/* <img src={mailingList} alt="Logo" height={"35rem"} width={"35rem"}></img> */}
        <Button 
          sx={{ padding: { sx: "10px" } }}
          onClick={() => handleMailingListModalOpen()}
        >
          <img 
            src={mailingList} 
            alt="Logo" 
            height={"22rem"} 
            width={"33rem"} 
          />
        </Button>

        <Button style={{ marginRight: "1.5rem" }}>
          {theme.modeName === "dark" ? (
            <TipsAndUpdatesIcon
              onClick={handleThemeChange}
              sx={{ color: "white", fontSize: "2rem" }}
            />
          ) : (
            <TipsAndUpdatesIcon
              onClick={handleThemeChange}
              sx={{ fontSize: "2rem" }}
            />
          )}
        </Button>

        {
          isConcertAdmin && (
            <Button
              variant="contained"
              size="small"
              sx={{
                // bgcolor: isScroller ? "rgb(94, 72, 146)" : "green",
                bgcolor: isScroller ? "rgb(167, 32, 32)" : "green",
                marginRight: "1rem",
                "&:hover": {
                  // bgcolor: "rgb(123, 136, 255)",
                  // bgcolor: "rgb(145, 156, 255)",
                  bgcolor: isScroller ? "rgb(167, 32, 32)" : "green",
                  // color: "black",
                },
                textTransform: "none",
                height: "90%",
                padding: "1rem",
              }}
              onClick={handleLyricsBroadcastToggle}
            >
              {isScroller ? `Stop Lyrics Broadcast` : `Start Lyrics Broadcast`}
            </Button>
          )

          // : concert?.hasFeatureDisplayCurrentSong === true ||
          //   concert?.hasFeatureLyricsBroadcast === true ? (
          //   <Button
          //     variant="contained"
          //     size="small"
          //     sx={{
          //       fontSize: {
          //         xs: "0.8rem",
          //         sm: "1rem",
          //       },
          //       lineHeight: "1.2rem",
          //       // bgcolor: "rgb(54, 53, 73)",
          //       bgcolor: "rgb(94, 72, 146)",
          //       marginRight: "1rem",
          //       "&:hover": {
          //         bgcolor: "rgb(123, 136, 255)",
          //         color: "black",
          //       },
          //       // textTransform: "none",
          //       height: "90%",
          //     }}
          //     startIcon={
          //       audientIneraction.activeScroll ? (
          //         <PlayCircleIcon />
          //       ) : (
          //         <StopCircleIcon />
          //       )
          //     }
          //     onClick={handleAudiantActiveScrollChange}
          //   >
          //     {!audientIneraction.activeScroll
          //       ? "View All Songs"
          //       : "View Current Song"}
          //   </Button>
          // ) : (
          //   ""
          // )
        }
      </Stack>
      {/* <Stack
        id="cur-song-name"
        sx={{
          display: "flex",
          flexDirection: "row",
          // marginTop: "0.5rem",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {(isConcertAdmin && expanded) ||
        (!isConcertAdmin && audientIneraction.activeScroll) ||
        (!isConcertAdmin && !audientIneraction.activeScroll && !isCameraOn) ? (
          <Typography
            sx={{
              fontFamily: `"Josefin Sans", sans-serif;`,
              ml: "1rem",
              // mt: "1rem",
            }}
            variant="h5"
          >
            {!audientIneraction.activeScroll
              ? currentSong?.title
              : songs.find((song) => song._id === expanded)?.title}
          </Typography>
        ) : (
          ""
        )} */}
      {/* {!audientIneraction.activeScroll && !isCameraOn && !isConcertAdmin && (
          <Button
            sx={{ mr: "1rem", mb: "0.3rem" }}
            onClick={() => setIsCameraOn(true)}
          >
            <LinkedCameraIcon />
          </Button>
        )} */}
      {/* {isCameraOn && !audientIneraction.activeScroll && !isConcertAdmin && (
          <>
            <Button
              sx={{ ml: "1rem" }}
              onClick={() => {
                setCameraFacing((prevState) =>
                  prevState === CAMERA_FACING.user
                    ? CAMERA_FACING.environment
                    : "user"
                );
              }}
            >
              <FlipCameraIosIcon />
            </Button>
            <Button sx={{ mr: "1rem" }} onClick={() => setIsCameraOn(false)}>
              <NoPhotographyIcon />
            </Button>
          </>
        )} */}
      {/* </Stack> */}
    </Stack>
  );
};

export default LyricsTopBar;
