import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Context } from "../../context/Context";
import classes from "./TopBar.module.css";
import { useLocation } from "react-router";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

function TopBar() {
  const location = useLocation();
  const isHomepage = location.pathname === "/";

  const { user, dispatch } = useContext(Context);

  const logoutHandler = () => dispatch({ type: "LOGOUT" });

  return (
    <div className={classes.top}>
      <div className={classes.topLeft}>
        <i className={`${classes.topIcon} fab fa-facebook-square`}></i>
        <i className={`${classes.topIcon} topIcon fab fa-instagram-square`}></i>
        <i className={`${classes.topIcon} fab fa-twitter-square`}></i>
        <i className={`${classes.topIcon} fab fa-pinterest-square`}></i>
      </div>
      <div className={classes.topCenter}>
        <ul className={classes.topList}>
          <li className={classes.topListItem}>
            {!isHomepage && (
              <Link className="link" to="/">
                HOME
              </Link>
            )}
          </li>
          <li className={classes.topListItem}>
            {/* <Link className="link" to="/about"> */}
            <a
              className="link"
              href="https://www.cicadamusic.net/"
              target="_blank"
            >
              ABOUT
            </a>
          </li>
          <li className={classes.topListItem}>
            {/* <Link className="link" to="/contact"> */}
            <a
              className="link"
              href="https://cicadamusic.net/#contact"
              target="_blank"
            >
              CONTACT
            </a>
          </li>
          {/* {user && (
            <li className={classes.topListItem}>
              <Link className="link" to="/write">
                WRITE
              </Link>
            </li>
          )} */}
          <li className={classes.topListItem} onClick={logoutHandler}>
            {user && "LOGOUT"}
          </li>
        </ul>
      </div>
      <div className={classes.topRight}>
        {user ? (
          <Link className="link" to="/settings">
            {user.photo !== "no-photo.jpg" && user.photo !== "" ? (
              <img
                className={classes.topImage}
                // src="https://scontent.ftlv2-1.fna.fbcdn.net/v/t1.6435-9/131419041_4179675872059236_6315853988360462261_n.jpg?_nc_cat=105&ccb=1-5&_nc_sid=09cbfe&_nc_ohc=JFg9eGkgLLoAX_D44gr&_nc_ht=scontent.ftlv2-1.fna&oh=595f6886396d9a72eac22a689082a15d&oe=61D8D59E"
                // src={user.profilePic}
                src={user.photo}
                alt="Profile ..."
              />
            ) : (
              <AccountCircleIcon sx={{color:'#444'}}/>
            )}
          </Link>
        ) : (
          <ul className={classes.topList}>
            <li className={classes.topListItem}>
              <Link className="link" to="/login">
                LOGIN
              </Link>
            </li>
            <li className={classes.topListItem}>
              <Link className="link" to="/register">
                REGISTER
              </Link>
            </li>
          </ul>
        )}
        {/* <i className={`${classes.topSearchIcon} fas fa-search`}></i> */}
      </div>
    </div>
  );
}

export default TopBar;
