export const loadConcertsGlobalStylesToStore = (concert, dispatch) => {
  concert.songs.map((song) => {
    const lyrics = song.lyrics;
    const fixedText = lyrics.replace(/\n\r|\r\n|\r/g, "\n");
    const lines = fixedText.split("\n");

    let pFontSize = null;
    let aFontSize = null;

    for (const line of lines) {
      const pMatch = line.match(/^P_FONT_SIZE=(.*?);?$/m);
      const aMatch = line.match(/^A_FONT_SIZE=(.*?);?$/m);
      if (pMatch) {
        pFontSize = pMatch[1];
      } else if (aMatch) {
        aFontSize = aMatch[1];
      } else if (pFontSize && aFontSize) {
        break;
      }
    }

    dispatch({
      type: "GLOBAL_CONCERT_STYLES_ADD_SONG",
      payload: {
        sondId: song._id,
        pFontSize,
        aFontSize,
      },
    });
  });
};
