import React, { useEffect, useState, useContext, useRef } from "react";
import axios from "axios";
import { io } from "socket.io-client";
import { AppBar, Box, Tab, Tabs, useMediaQuery} from "@mui/material";
import { useLocation } from "react-router";
import { Context } from "../../context/Context";
import { PATH_SUFFIX } from "../../constants/frontAppConstants";
import { Stack } from "@mui/system";
import InfoIcon from '@mui/icons-material/Info';

import LyricsFooter from "../../components/lyricsFootter_V2/LyricsFooter";
import ActiveScrollMode from "../../components/lyricsMain_V2/ActiveScrollMode";
import PassiveScrollMode from "../../components/lyricsMain_V2/PassiveScrollMode";
import { TabPanel } from "../../components/tabPanel/TabPanel";
import {a11yProps}  from "../../components/tabPanel/TabPanel";

import { loadConcertsGlobalStylesToStore } from "../../utils/loadConcertsGlobalStylesToStore";

import usePageVisibility from "../../Hooks/usePageVisibility";
import LyricsInfoModal from "../../components/lyricsMain_V2/LyricsInfoModal";
import LyricsTopBar from "../../components/lyricsMain_V2/LyricsTopBar";

import Info from "../../components/lyricsMain_V2/Info";
import Tips from "../../components/lyricsMain_V2/Tips";
import ShopifyCollectionComponent from "../../components/lyricsMain_V2/ShopifyCollectionComponent";
import TipsTemp from "../../components/lyricsMain_V2/TipsTemp";
import LyricsMailingListModal from "../../components/lyricsMain_V2/LyricsMailingListModal";
import { mailingModalLocalStorageOnConcertLoad } from "../../utils/localStorageUtils";

const tabStyle = {
  padding:"0", 
  margin:"0", 
  maxWidth: 1,
  fontWeight: 300,
  textTransform: "none"
};

export const CAMERA_FACING = {
  user: "user",
  environment: { exact: "environment" },
};

const SingleShow = () => {
  const scrollingWindowRef = useRef(null);
  const tabPanelRef = useRef(null);
  const location = useLocation();
  const {
    isScroller,
    isConcertAdmin,
    audientIneraction,
    user,
    dispatch,
    theme,
  } = useContext(Context);

  /** Hook that re renders the page whenever its visibility canges */
  const visible = usePageVisibility();

  // const [isModalOpen, setModalOpen] = useState(false);
  // const handleModalOpen = () => setModalOpen(true);
  // const handleModalClose = () => setModalOpen(false);

  // TODO: Manage this
  const [isMailingListModalOpen, setMailingListModalOpen] = useState(false);
  const handleMailingListModalOpen = () => setMailingListModalOpen(true);
  const handleMailingListModalClose = () => setMailingListModalOpen(false);

  // Handeling Tab change
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  const concertId = location.pathname.split("/")[2];

  const [concert, setConcert] = useState({});

  const [songs, setSongs] = useState([]); // concert.songs

  const [expanded, setExpanded] = useState(null);

  const [themeChecked, setThemeChecked] = useState(false);

  const [currentSong, setCurrentSong] = useState({
    id: "",
    title: "",
    videoStreams: [],
    photo: "",
    lyrics: "",
    lyricsSliced: [],
  });

  const [lyricsSliceIndex, setLyricsSliceIndex] = useState(null);

  const [socket, setSocket] = useState({});

  const [displayLyricsFooter, setDisplayLyricsFooter] = useState(true);

  const [isCameraOn, setIsCameraOn] = useState(false);
  const [cameraFacing, setCameraFacing] = useState(CAMERA_FACING.environment);

  // MediaQuery to determin Tabs component variable to be scrolable or fullWidth
  const isTabsComponentVariantScrolable = useMediaQuery('(max-width:390px)');

  useEffect(() => {
    if(isConcertAdmin && !concert?.hasFeatureLyricsBroadcast) {
      setDisplayLyricsFooter(false);
    } 
    else if (
      isConcertAdmin ||
      concert.merchUrl ||
      concert.merchUrl?.trim() === "" ||
      concert.donationUrl ||
      concert.donationUrl?.trim() === ""
    ) {
      setDisplayLyricsFooter(true);
    } else {
      setDisplayLyricsFooter(false);
    }


  }, [isConcertAdmin, concert.merchUrl, concert.donationUrl]);

  const handleThemeChange = (event) => {
    dispatch({ type: "TOGGLE_THEME" });
    setThemeChecked(event.target.checked);
  };

  const handleAudiantActiveScrollChange = () => {
    if (!audientIneraction.activeScroll) {
      setExpanded(null);
    }
    dispatch({ type: "AUDIENT_ACTIVE_SCROLL_TOGGLE" });
  };

  const handleAccordionChange = ({
    isExpanded,
    songId, // panel has the value of songId
    title,
    videoStreams,
    photo,
    lyrics,
    lyricsSliced,
  }) => {
    console.log("handleAccordionChange:", { songId });
    setExpanded(isExpanded ? songId : null);
    if (!isConcertAdmin) return;
    // All functunality below effects conects admin only
    if (isExpanded === null) {
      setCurrentSong({
        id: "",
        title: "",
        videoStreams: [],
        photo: "",
        lyrics: "",
        lyricsSliced: [],
      });
      setLyricsSliceIndex(null);
    } else if (isExpanded !== null) {
      setCurrentSong({
        id: songId,
        title,
        videoStreams,
        photo,
        lyrics,
        lyricsSliced,
      });
      setLyricsSliceIndex(0);
    }
  };

  const handleLyricsBroadcastToggle = () => {
    dispatch({ type: "UPDATE_SCROLLER", payload: !isScroller });
  };

  const HandleArrowUpClick = () => {
    handleAdminBroadcast("up");
  };

  const HandleArrowDownClick = () => {
    handleAdminBroadcast("down");
  };

  const handleAdminBroadcast = async (direction) => {
    let currSliceIndex = lyricsSliceIndex;
    if (
      isScroller === true &&
      currentSong.id !== "" &&
      lyricsSliceIndex !== null
    ) {
      if (
        (currSliceIndex === currentSong.lyricsSliced.length - 1 &&
          direction === "down") ||
        (currSliceIndex === 0 && direction === "up")
      ) {
        currSliceIndex = currSliceIndex;
      } else {
        if (direction === "up") {
          currSliceIndex--;
        } else if (direction === "down") {
          currSliceIndex++;
        }
      }
    }
    setLyricsSliceIndex(currSliceIndex);
    console.log({currSliceIndex});
  };

  useEffect(() => {
    if (concert.hasFeatureLyricsBroadcast && isConcertAdmin) {
      if (!lyricsSliceIndex) setLyricsSliceIndex(0);
      dispatch({ type: "IS_ADMIN_LYRICS_BROADCAST_MODE_SET_TRUE" });
      console.log({ lyricsSliceIndex });
    }
  }, [concert.hasFeatureLyricsBroadcast, isConcertAdmin]);

  // // Welcome modal effect - display configuration
  // useEffect(() => {
  //   !isConcertAdmin && handleModalOpen(true);
  // }, []);

  useEffect(() => {
    if (isScroller && concertId) {
      const sock = io();
      sock.on("connect", () => {
        setSocket(sock);
      });

      return () => {
        sock.close();
      };
    }
  }, [concertId, isScroller]);



  // Effect for the Concert Admin - Socket Emmiter
  useEffect(() => {
    const emitBroadcat = async () => {
      if (typeof socket.emit === "function" && expanded) {
        await socket.emit("lyrics-broadcast", {
          concertId,
          songId: currentSong.id,
          songTitle: currentSong.title,
          songPhoto: currentSong.photo,
          songLyrics: currentSong.lyrics,
          lyricsSliceIndex,
        });
      }
    };

    if (isConcertAdmin && isScroller && concert.hasFeatureLyricsBroadcast) {
      emitBroadcat();
    }
  }, [lyricsSliceIndex, currentSong?.id, expanded]);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    const emitDisplayCurrentSong = async () => {
      await socket.emit("display-current-song", {
        concertId,
        songId: currentSong.id,
      });

      try {
        const res =
          user &&
          (await axios.put(
            `${PATH_SUFFIX}/concerts/${concertId}`,
            {
              currentSongIndex:
                songs?.findIndex((song) => song._id === currentSong.id) || 0,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user.token}`,
              },
              cancelToken: cancelToken.token,
            },
          ));
        console.log({updatedConcert: res.data.data});
      } catch (err) {
        console.log(err);
      }
    };

    if (
      typeof socket.emit === "function" &&
      isConcertAdmin &&
      isScroller &&
      (concert.hasFeatureDisplayCurrentSong || concert.hasFeatureLyricsBroadcast) &&
      expanded 
    ) {
      emitDisplayCurrentSong();
    }

    return () => {
      cancelToken.cancel();
    };;
  }, [currentSong?.id], expanded);

  // The useRef allows the Socket Listener effect to receive the last version of songs
  const songsRef = useRef(songs);

  useEffect(() => {
    songsRef.current = songs;
  }, [songs]);

  // Effect for the audiance - Socket Listener
  useEffect(() => {
    const effectHelperFunctionSetCurrentSong = (song) => {
      song &&
        setCurrentSong({
          id: song._id,
          title: song.title,
          videoStreams: song.videoStreams,
          photo: song.photo,
          lyrics: song.lyrics,
          lyricsSliced: song.lyricsSliced,
        });
    };

    const sock = io();
    const handleViewLyricsBroadcast = () => {
      sock.on("lyrics-data", (data) => {
        console.log("soket says", data);
        if (concertId === data.concertId) {
          setLyricsSliceIndex(data.lyricsSliceIndex);
          const song = songs.find((s) => s._id === data.songId);
          effectHelperFunctionSetCurrentSong(song);
        }
      });
    };

    const handleReceiveCurrentSong = () => {
      sock.on("current-song-data", (data) => {
        console.log("soket says", data);
        if (concertId === data.concertId && currentSong.id !== data.songId) {
          const song = songsRef.current.find((s) => s._id === data.songId);
          effectHelperFunctionSetCurrentSong(song);
        }
      });
    };

    if (!isConcertAdmin && !isScroller) {
      if (concert.hasFeatureLyricsBroadcast) {
        handleViewLyricsBroadcast();
      } else {
        handleReceiveCurrentSong();
      }
    }

    return () => {
      sock.close();
    };
  }, [concertId, currentSong?.id]);


  const fetchSingleConcert = async (cancelToken) => {
    try {
      const res = await axios.get(`${PATH_SUFFIX}/concerts/${concertId}`, {
        cancelToken: cancelToken.token,
      });

      const concertInnerScope = res.data.data;
      setConcert(concertInnerScope);

      // mailingModal managment start    
      mailingModalLocalStorageOnConcertLoad(concertInnerScope, setMailingListModalOpen)

      // Set Concerts Global Styles
      dispatch({
        type: "GLOBAL_CONCERT_STYLES_SET_CONCERT_ID",
        payload: concertInnerScope._id,
      });
      loadConcertsGlobalStylesToStore(concertInnerScope, dispatch);
      setSongs(concertInnerScope.songs);
      setCurrentSong({
        ...concertInnerScope.songs[concertInnerScope.currentSongIndex],
        id: concertInnerScope.songs[concertInnerScope.currentSongIndex]._id,
      });
      if (
        concertInnerScope.hasFeatureDisplayCurrentSong === true ||
        concertInnerScope.hasFeatureLyricsBroadcast === true
      ) {
        dispatch({ type: "AUDIENT_ACTIVE_SCROLL_SET_FALSE" });
      }
      if (
        concertInnerScope.hasFeatureDisplayCurrentSong === false &&
        concertInnerScope.hasFeatureLyricsBroadcast === false
      ) {
        dispatch({ type: "AUDIENT_ACTIVE_SCROLL_SET_TRUE" });
      }
      console.log("CONCERT fetched now", concertInnerScope);
    } catch (err) {
      if (axios.isCancel(err)) {
        console.log(`request to get ${concertId} concert was canceled`);
      } else {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    fetchSingleConcert(cancelToken);

    return () => {
      cancelToken.cancel();
    };
  }, [concertId]);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    
    console.log({ visible });
    if (
      visible &&
      !audientIneraction.activeScroll &&
      !isConcertAdmin &&
      concert.hasFeatureLyricsBroadcast &&
      concert.hasFeatureDisplayCurrentSong
    ) {
      fetchSingleConcert(cancelToken);
    }

    return () => {
      cancelToken.cancel();
    };
  }, [visible]);
  

  return (
    <Box
      id="single-show"
      aria-label="single show main window"
      sx={{
        overflow: "hidden",
        // overflow: "scroll",
        height: "100vh",
        width: {
          xs: "100%",
          sm: "100%",
          md: "45%",
        },
        position: "relative",
      }}
    >
      {/* <LyricsInfoModal {...{ isModalOpen, handleModalClose, concert }} /> */}
      <LyricsMailingListModal {...{ isMailingListModalOpen, handleMailingListModalClose, concert }} />

      {visible && (
        <Stack
          id="is-visible-section"
          direction="column"
          spacing={1}
          p={!isCameraOn && 1}
        >

          <LyricsTopBar
            {...{
              themeChecked,
              handleThemeChange,
              handleLyricsBroadcastToggle,
              handleAudiantActiveScrollChange,
              setIsCameraOn,
              concert,
              expanded,
              currentSong,
              songs,
              isCameraOn,
              CAMERA_FACING,
              setCameraFacing,

              handleMailingListModalOpen,

            }}
          />
          {isConcertAdmin ? (
            <ActiveScrollMode
              {...{
                scrollingWindowRef,
                handleAccordionChange,
                songs,
                expanded,
                lyricsSliceIndex,
                boxHeight: displayLyricsFooter ? "60vh" : "80vh",
            }}/>
            ) : (
         
            <Box id="tabs-container" >
              <AppBar 
                position="static" 
                sx={{ 
                  maxWidth: {
                    xs: "96%",
                    sm: "98%",
                    md: "96%",
                    xl: "98%"
                  },
                  marginLeft: '0.5rem', 
                  padding : "0",
                  bgcolor: theme.appBar.backgroundColor, 
                  color: "white"
                }}
              >

                <Tabs
                  value={tabIndex}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  textColor="inherit"
                  variant={isTabsComponentVariantScrolable ? "scrollable" : "fullWidth"}
                  scrollButtons                  
                  // scrollButtons="auto"
                  allowScrollButtonsMobile
                  aria-label="full width tabs example"
                  sx={{
                    display: 'flex',
                    alignItems: "space-around",
                  }}
                >
                {/* TODO: Labels here */}
                  <Tab label={concert?.tabLabels?.realtime || "Realtime"} {...a11yProps(0)} sx={{...tabStyle}}/>
                  <Tab label={concert?.tabLabels?.program ||"Program"} {...a11yProps(1)} sx={{...tabStyle}}/>
                  {concert.donationUrl &&
                    <Tab label={concert?.tabLabels?.tips ||"Tips"} {...a11yProps(2)} sx={{...tabStyle}}/>
                  }
                  {concert.shopifyCollectionId &&
                    <Tab label={concert?.tabLabels?.merch ||"Merch"} {...a11yProps(3)} sx={{...tabStyle}}/>
                  }


                </Tabs>
              </AppBar>

              <TabPanel 
                value={tabIndex} 
                index={0} dir={theme.direction} 
                overflow={"hidden"}
              >
                <PassiveScrollMode
                  {...{
                    concert,
                    scrollingWindowRef,
                    currentSong,
                    lyricsSliceIndex,
                    boxHeight: "78vh",
                    cameraFacing,
                    setCameraFacing,
                    isCameraOn,
                    setIsCameraOn,
                    aspectRatio: 16 / 9,
                  }}
                />
              </TabPanel>
              <TabPanel id="active-scroll-tab" value={tabIndex} index={1} dir={theme.direction} >
                <ActiveScrollMode
                  {...{
                    handleAccordionChange,
                    songs,
                    expanded,
                    lyricsSliceIndex,
                    boxHeight: "80vh",
                  }}
                />
              </TabPanel>
              {concert.donationUrl &&
                <TabPanel value={tabIndex} index={2} dir={theme.direction} overflow={"hidden"}>              
                  <Tips concert={concert} boxHeight={"78vh"}/>
                </TabPanel>
              }
              {concert.shopifyCollectionId &&
                <TabPanel value={tabIndex} index={3} dir={theme.direction}>             
                  <ShopifyCollectionComponent shopifyCollectionId={concert.shopifyCollectionId}/>
                </TabPanel>
              }
              <TabPanel value={tabIndex} index={4} dir={theme.direction} sx={{margin: 0}}>  
                <Info concert={concert}/>
              </TabPanel>
            </Box>
            
          )}
          { isConcertAdmin && concert.hasFeatureLyricsBroadcast && (
            <Box
              id="lyrics-footer"
              sx={{
                height: !isCameraOn ? "30vh" : "20vh",
                width: "fixed",
                backgroundColor: theme.footer.backgroundColor,
                position: "sticky",
                bottom: 0,
                left: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <LyricsFooter
                isCameraOn={isCameraOn}
                concert={concert}
                arrowUpClick={HandleArrowUpClick}
                arrowDownClick={HandleArrowDownClick}
              />
            </Box>
          )}
        </Stack>
      )}
    </Box>
  );
};

export default SingleShow;
