import React, { useEffect, useState, useContext, useRef } from "react";
import { useLocation } from "react-router";
import axios from "axios";
import classes from "./SingleConcertAudiance.module.css";
import { Link } from "react-router-dom";
import { Context } from "../../context/Context";
import { PATH_SUFFIX } from "../../constants/frontAppConstants";
import Lyrics from "../lyrics/Lyrics";
import Switch from "../switch/Switch";
import { io } from "socket.io-client";
import LyricsFooter from "../lyricsFootter/LyricsFooter";
import { Box, Divider } from "@mui/material";

const SingleConcertAudiance = () => {
  const location = useLocation();

  const concertId = location.pathname.split("/")[2];

  const [concert, setConcert] = useState({});
  const date = concert ? new Date(concert.startsAt) : null;
  const dateString = date.toDateString();

  const { user, dispatch, theme } = useContext(Context);

  const [artist, setArtist] = useState(""); // concert.user.username
  const [title, setTitle] = useState(""); // concert.eventName
  const [messageToAudiance, setMessageToAudiance] = useState(""); // concert.messageToTheAudiance
  const [songs, setSongs] = useState([]); // concert.songs
  const [eventName, setEventName] = useState("");
  const [isDarkMode, setIsDarkMode] = useState(true);

  const [socket, setSocket] = useState();

  const [scrollY, setScrollY] = useState();

  const handleToggle = () => {
    setIsDarkMode((isDarkMode) => !isDarkMode);
    dispatch({ type: "TOGGLE_THEME" });
  };
  const darkMode = isDarkMode ? classes.darkMode : "";

  // TODO: Make socket emit more eficiant
  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    const fetchSingleConcert = async () => {
      try {
        const res = await axios.get(`${PATH_SUFFIX}/concerts/${concertId}`, {
          cancelToken: cancelToken.token,
        });
        setConcert(res.data.data);
        setArtist(res.data.data.user.username);
        setTitle(res.data.data.eventName);
        setMessageToAudiance(res.data.data.messageToAudience);
        setSongs(res.data.data.songs);
        setEventName(res.data.data.eventName);
      } catch (err) {
        if (axios.isCancel(err)) {
          console.log(`request to ${concertId} concert was canceled`);
        } else {
          console.log(err);
        }
      }
    };
    fetchSingleConcert();

    return () => {
      cancelToken.cancel();
    };
  }, [concertId]);

  // TODO: Check if the useRef is apropriate in this case.
  const lastPositionRef = useRef(0);
  useEffect(() => {
    const sock = io();
    const handlePassiveScroll = () => {
      sock.on("scrollingData", (data) => {
        const { concertId: receivedConcertId, position } = data;
        console.log("soket says", data);
        if (receivedConcertId === concertId) {
          // const offset = position >= lastPositionRef.current ? 66.4 : -66.4;
          const offset = 0;
          // window.scrollTo(0, position + offset);
          // window.scrollTo(0, position);
          window.scrollTo(0, position * document.body.clientHeight);
          lastPositionRef.current = position;
          // console.log('offset', offset);
          console.log(window.scrollY);
          // TODO: Check The next properties
          // console.log(document.scrollingElement.scrollTop)
          // console.log(document.scrollingElement.scrollHeight - window.innerHeight)
          // TODO: In order to make it tresponsive in all deviced check out:
          //      Scrolling position conversation where I mentioned material ui in GPT
        }
      });
    };

    handlePassiveScroll();

    return () => {
      sock.close();
    };
  }, [concertId]);

  return (
    <Box
      sx={{
        // width: "100vw",
        padding: "1rem",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        ...theme.general,
      }}
    >
      {/* <div className={`${classes.singleConcert} ${darkMode}`}> */}
      <div className={classes.singleConcertWrapper}>
        {/* {concert.photo && (
          <img
            src={PUBLIC_FOLDER + concert.photo}
            alt="Concerts picture"
            className={classes.singleConcertImg}
          />
        )} */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            // justifyContent: "flex-end",
            marginBottom: "1rem",
          }}
        >
          <button
            style={{
              maxWidth: "50%",
              cursor: "pointer",
              alignSelf: "flex-end",
              display: "none",
            }}
          >
            Stop Being the Scroller
          </button>
          <Switch
            isOn={isDarkMode}
            handleToggle={handleToggle}
            onColor="rgb(18, 18, 18)"
            text="Dark / Light Mode"
          />
        </div>
        <div
          className={classes.singleConcertInfo}
          style={{ textAlign: "left" }}
        >
          <span className={classes.singleConcertDate}>
            Concert date: {dateString}
          </span>
          <span className={classes.singleConcertAuthor}>
            Author: <b>{artist}</b>
          </span>
        </div>
        <Divider sx={{ bgcolor: "#879EB8", mb: "2rem", mt: "0.5rem" }} />
        {/* TODO: Arrange the concerts && implement all changes in Admin page */}
        <p
          className={`${classes.singleConcertTitle} ${darkMode}`}
          style={{
            // ...theme.general, // NO THEME CHANGE HERE
            fontFamily: `"Josefin Sans", sans-serif`,
            textAlign: "left",
            background: "rgb(70, 70, 70)", // IMPORTANT
            color: "white",
            padding: "1rem",
            borderRadius: "5px",
            fontSize: "1.7rem",
            lineHeight: "2.2rem",
          }}
        >
          {eventName}
        </p>
        {/* <Divider sx={{bgcolor: "#879EB8", mb: "2rem", mt: "0.5rem"}}/> */}
        <p
          className={`${classes.singleConcertDesc} ${darkMode}`}
          style={{
            ...theme.general,
            border: "2px solid #7A49A5",
            borderRadius: "5px",
            padding: "1rem",
            margin: "4rem 0",
            fontSize: "1.7rem",
            lineHeight: "2.2rem",
            fontFamily: `"Varela Round", sans-serif`,
          }}
        >
          {messageToAudiance}
        </p>

        <div className={classes.songsWrapper}>
          {songs &&
            songs.map((song) => {
              if (song.isVisible) {
                return (
                  <div key={song._id} className={classes.songContainer}>
                    <p
                      className={`${classes.songTitle} ${darkMode}`}
                      style={{
                        ...theme.general,
                        fontSize: "1.7rem",
                        lineHeight: "2.2rem",
                        margin: "2rem 0",
                      }}
                    >
                      {song.title}
                    </p>
                    {/* TODO: fix that shit! */}
                    <br></br>
                    <br></br>
                    <br></br>
                    <Lyrics lyrics={song.lyrics} />

                    <br></br>
                  </div>
                );
              }
            })}
        </div>
        <LyricsFooter isDarkMode={isDarkMode} />
      </div>
      {/* </div> */}
    </Box>
  );
};

export default SingleConcertAudiance;
