import React, { useEffect, useRef, useState, useContext } from "react";

import { Context } from "../../context/Context";
import { Box } from "@mui/material";

import styles from "./shopifyCollectionComponent.module.css";

const ShopifyCollectionComponent = ({ shopifyCollectionId }) => {
  const { theme } = useContext(Context);

  const divRef = useRef(null);
  const [shopifyLoaded, setShopifyLoaded] = useState(false);

  useEffect(() => {
    const scriptURL =
      "https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js";
    const script = document.createElement("script");
    script.async = true;
    script.src = scriptURL;
    script.onload = () => {
      if (window.ShopifyBuy) {
        setShopifyLoaded(true);
      } else {
        console.error("ShopifyBuy is not defined on window");
      }
    };
    script.onerror = () => {
      console.error("An error occurred while loading the Shopify script");
    };
    (
      document.getElementsByTagName("head")[0] ||
      document.getElementsByTagName("body")[0]
    ).appendChild(script);
  }, []);

  useEffect(() => {
    if (!shopifyLoaded) {
      return;
    }

    const ShopifyBuyInit = () => {
      var client = window.ShopifyBuy.buildClient({
        domain: "cicada-test-5892.myshopify.com",
        storefrontAccessToken: "e34a001a82eacb2ce28ef0f0897b507d",
      });
      window.ShopifyBuy.UI.onReady(client).then(function (ui) {
        ui.createComponent("collection", {
          id: shopifyCollectionId && shopifyCollectionId ,
          node: divRef.current,
          moneyFormat: "%24%7B%7Bamount%7D%7D",
          // Include the options object here
          options: {
            "product": {
              "styles": {
                "product": {
                  "@media (min-width: 601px)": {
                    "max-width": "calc(25% - 20px)",
                    "margin-left": "20px",
                    "margin-bottom": "50px",
                    "width": "calc(25% - 20px)"
                  }
                },
                "title": {
                  "font-family": "Montserrat, sans-serif"
                },
                "button": {
                  "font-family": "Montserrat, sans-serif",
                  "font-weight": "bold",
                  ":hover": {
                    "background-color": "#1e7595"
                  },
                  "background-color": "#2182a6",
                  ":focus": {
                    "background-color": "#1e7595"
                  },
                  "border-radius": "5px"
                },
                "price": {
                  "font-family": "Montserrat, sans-serif",
                  "font-weight": "bold",
                  "font-size": "16px"
                },
                "compareAt": {
                  "font-family": "Montserrat, sans-serif",
                  "font-weight": "bold",
                  "font-size": "13.6px"
                },
                "unitPrice": {
                  "font-family": "Montserrat, sans-serif",
                  "font-weight": "bold",
                  "font-size": "13.6px"
                }
              },
              "buttonDestination": "modal",
              "contents": {
                "options": false
              },
              "text": {
                "button": "Select"
              },
              "googleFonts": [
                "Montserrat"
              ]
            },
            "productSet": {
              "styles": {
                "products": {
                  "@media (min-width: 601px)": {
                    "margin-left": "-20px"
                  }
                }
              }
            },
            "modalProduct": {
              "contents": {
                "img": false,
                "imgWithCarousel": true,
                "button": false,
                "buttonWithQuantity": true
              },
              "styles": {
                "product": {
                  "@media (min-width: 601px)": {
                    "max-width": "100%",
                    "margin-left": "0px",
                    "margin-bottom": "0px"
                  }
                },
                "button": {
                  "font-family": "Montserrat, sans-serif",
                  "font-weight": "bold",
                  ":hover": {
                    "background-color": "#1e7595"
                  },
                  "background-color": "#2182a6",
                  ":focus": {
                    "background-color": "#1e7595"
                  },
                  "border-radius": "5px"
                },
                "title": {
                  "font-family": "Helvetica Neue, sans-serif",
                  "font-weight": "bold",
                  "font-size": "26px",
                  "color": "#4c4c4c"
                },
                "price": {
                  "font-family": "Helvetica Neue, sans-serif",
                  "font-weight": "normal",
                  "font-size": "18px",
                  "color": "#4c4c4c"
                },
                "compareAt": {
                  "font-family": "Helvetica Neue, sans-serif",
                  "font-weight": "normal",
                  "font-size": "15.299999999999999px",
                  "color": "#4c4c4c"
                },
                "unitPrice": {
                  "font-family": "Helvetica Neue, sans-serif",
                  "font-weight": "normal",
                  "font-size": "15.299999999999999px",
                  "color": "#4c4c4c"
                }
              },
              "googleFonts": [
                "Montserrat"
              ],
              "text": {
                "button": "Add to cart"
              }
            },
            "option": {},
            "cart": {
              "styles": {
                "button": {
                  "font-family": "Montserrat, sans-serif",
                  "font-weight": "bold",
                  ":hover": {
                    "background-color": "#1e7595"
                  },
                  "background-color": "#2182a6",
                  ":focus": {
                    "background-color": "#1e7595"
                  },
                  "border-radius": "5px"
                }
              },
              "text": {
                "total": "Subtotal",
                "button": "Checkout"
              },
              "googleFonts": [
                "Montserrat"
              ]
            },
            "toggle": {
              "styles": {
                "toggle": {
                  "font-family": "Montserrat, sans-serif",
                  "font-weight": "bold",
                  "background-color": "#2182a6",
                  ":hover": {
                    "background-color": "#1e7595"
                  },
                  ":focus": {
                    "background-color": "#1e7595"
                  }
                }
              },
              "googleFonts": [
                "Montserrat"
              ]
            }
          },
        });
      });
    };

    if (window.ShopifyBuy) {
      if (window.ShopifyBuy.UI) {
        ShopifyBuyInit();
      }
    }
  }, [shopifyLoaded]);

  return (
    <Box
      style={{
        height: "100%",
        ...theme.accordeon,
        backgroundColor: 'rgb(184, 184, 184)',
        padding: "1rem",
        paddingBottom: "0",
        textAlign: "center",
        position: "relative",
        zIndex: 3,
      }}
    >

      <div
        id="collection-component-1685480946760"
        // id="collection-component-1690834124306"
        ref={divRef}
        className={styles.shopifyMainDiv}
      ></div>
    </Box>
  );
};

export default ShopifyCollectionComponent;



// import React, { useEffect } from 'react';

// const ShopifyComponent = () => {
//   useEffect(() => {
//     const scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';

//     const loadScript = new Promise((resolve) => {
//       const script = document.createElement('script');
//       script.async = true;
//       script.src = scriptURL;
//       script.onload = () => resolve();
//       (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
//     });

//     loadScript.then(() => {
//       const ShopifyBuy = window.ShopifyBuy;
//       const ShopifyBuyInit = () => {
//         var client = ShopifyBuy.buildClient({
//           domain: 'cicada-test-5892.myshopify.com',
//           storefrontAccessToken: 'e34a001a82eacb2ce28ef0f0897b507d',
//         });
//         ShopifyBuy.UI.onReady(client).then(function (ui) {
//           ui.createComponent('collection', {
//             // ... the rest of your options ...
//           });
//         });
//       }

//       if (ShopifyBuy.UI) {
//         ShopifyBuyInit();
//       }
//     });
//   }, []);

//   return <div id='collection-component-1690834124306'></div>;
// }

// export default ShopifyComponent;



// import React, { useEffect, useRef, useState } from 'react';

// const ShopifyCollectionComponent = () => {
//   const divRef = useRef(null);
//   const [shopifyLoaded, setShopifyLoaded] = useState(false);

//   useEffect(() => {
//     const scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
//     const script = document.createElement('script');
//     script.async = true;
//     script.src = scriptURL;
//     script.onload = () => {
//       if (window.ShopifyBuy) {
//         setShopifyLoaded(true);
//       } else {
//         console.error("ShopifyBuy is not defined on window");
//       }
//     };
//     script.onerror = () => {
//       console.error("An error occurred while loading the Shopify script");
//     };
//     (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
//   }, []);

//   useEffect(() => {
//     if (!shopifyLoaded) {
//       return;
//     }

//     const ShopifyBuyInit = () => {
//       var client = window.ShopifyBuy.buildClient({
//         domain: 'cicada-test-5892.myshopify.com',
//         storefrontAccessToken: 'e34a001a82eacb2ce28ef0f0897b507d',
//       });
//       window.ShopifyBuy.UI.onReady(client).then(function (ui) {
//         ui.createComponent('collection', {
//           id: '443133788464',
//           node: divRef.current,
//           moneyFormat: '%24%7B%7Bamount%7D%7D',
//           options: {
//             // Replace with your options object
//             /*...*/
//           }
//         });
//       });
//     };

//     if (window.ShopifyBuy) {
//       if (window.ShopifyBuy.UI) {
//         ShopifyBuyInit();
//       }
//     }
//   }, [shopifyLoaded]);

//   return <div id='collection-component-1690834124306' ref={divRef}></div>;
// };

// export default ShopifyCollectionComponent;


