import React, { useContext, useEffect, useRef, useState } from "react";
import classes from "./LyricsFooter.module.css";
import axios from "axios";
import { useLocation } from "react-router-dom";

import { Context } from "../../context/Context";
import { purchaseStageOptions, orderTypes } from "../../context/Context";
import { PATH_SUFFIX } from "../../constants/frontAppConstants";

import AdminScrollFooter from "./AdminScrollFooter";
import EmailForm from "./EmailForm";
import MerchOrDonation from "./MerchOrDonation";
import ThankyouLyricsFooter from "./ThankyouLyricsFooter";
import CustomAlert from "../CustomAlert/CustomAlert";
import { Box } from "@mui/material";

const LyricsFooter = ({ arrowUpClick, arrowDownClick }) => {
  const location = useLocation();
  const concertId = location.pathname.split("/")[2];

  const { isScroller, theme } = useContext(Context);
  const { dispatch, errorMessage, purchaseStage, isFetching, orderType } =
    useContext(Context);

  const [showAlert, setShowAlert] = useState(false);
  const [emailFormLabel, setEmailFormLabel] = useState("We'll send you a link to the artists' merch collection.")
  const emailRef = useRef();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowAlert(false);

    dispatch({ type: "SUBMIT_LEAD_START" });

    try {
      const res = await axios.post(PATH_SUFFIX + "/leads", {
        email: emailRef.current.value,
        concertId,
        orderType,
      });
      dispatch({
        type: "SUBMIT_LEAD_SUCCESS",
        payload: purchaseStageOptions.THANK_YOU_POPUP,
      });
    } catch (err) {
      err.response.data.error
        ? dispatch({
            type: "SUBMIT_LEAD_FAILURE",
            payload: err.response.data.error,
          })
        : dispatch({
            type: "SUBMIT_LEAD_FAILURE",
            payload: "something went wrong...",
          });

      console.error(err.response.data.error);
      setShowAlert(true);
    }
  };

  const removeAlertHandler = () => setShowAlert(false);

  const handleMerch = () => {
    dispatch({
      type: "UPDATE_PURCHASE_STAGE",
      payload: purchaseStageOptions.GIVE_EMAIL,
    });
    dispatch({ type: "UPDATE_ORDER_TYPE", payload: orderTypes.MERCH });
    setEmailFormLabel("We'll send you a link to the artists' merch collection.")
  };
  const handleDonation = () => {
    dispatch({
      type: "UPDATE_PURCHASE_STAGE",
      payload: purchaseStageOptions.GIVE_EMAIL,
    });
    dispatch({ type: "UPDATE_ORDER_TYPE", payload: orderTypes.DONATION });
    setEmailFormLabel("We'll send you a link through which you can donate to this artist.")
  };

  return (
    <Box
      id="lyrics-footer-wrapper"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent:"center",
        textAlign: 'center',
        height: "40%",
        padding: '10% 0',
        width: '100%',
        position: "sticky",
        bottom: 0,
        left: 0,
        ...theme.general,
        // bgcolor: "secondary.main",
      }}
      className={classes.theme}
    >
        {isScroller ? (
          <AdminScrollFooter
            arrowUpClick={arrowUpClick}
            arrowDownClick={arrowDownClick}
          />
        ) : !purchaseStage ? (
          <MerchOrDonation
            handleMerch={handleMerch}
            handleDonation={handleDonation}
          />
        ) : purchaseStage === purchaseStageOptions.GIVE_EMAIL && !showAlert ? (
          <EmailForm
            handleSubmit={handleSubmit}
            ref={emailRef}
            disabled={isFetching}
            emailFormLabel={emailFormLabel}
          />
        ) : (
          purchaseStage === purchaseStageOptions.THANK_YOU_POPUP && (
            <ThankyouLyricsFooter />
          )
        )}
        {showAlert && (
          <CustomAlert
            msg_1={errorMessage}
            onRemoveAlert={removeAlertHandler}
            passStyle={{ zIndex: 9, padding: 0 }}
            passStyleMsg1={{
              backgroundColor: "rgb(128, 0, 128, 0.9)",
              height: "100%",
              width: "100%",
              lineHeight: "2rem",
            }}
          />
        )}
    </Box>
  );
};

export default LyricsFooter;
