import Box from "@mui/material/Box";
import PropTypes from "prop-types";

export function TabPanel(props) {
  const { children, value, index, overflow, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
      // style={{ height: 'calc(100vh - 64px)', overflow: 'auto' }}
      style={{
        // overflow: "hidden",
        // height: "100%",
        // padding: 0,
        // margin: 0,
        // height: "calc(100vh - 64px)",
        height: "calc(100vh - 128px)",
        overflow: overflow ? overflow : "auto",
      }}
    >
      {value === index && <Box sx={{ padding: "0.5rem" }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
