import React, { useEffect } from "react";
import styles from "./ThankYou.module.css";
import { Button } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

const ThankYou = () => {
  const location = useLocation();
  let navigate = useNavigate();

  // Extract the redirect parameter from the URL
  const queryParams = new URLSearchParams(location.search);
  const concertId = queryParams.get("concertId");
  const artistName = queryParams.get("artistName");
  

  // http://localhost:3000/thankyou?concertId=6434510058a1211bf03ece27

  useEffect(() => {
    setTimeout(() => {
      navigate(`../concert/${concertId}`);
    }, 3000);
  }, [concertId, artistName]);

  return (
    <div className={styles["thankyou-container"]}>
      <div className={styles.content}>
        <div className={styles["wrapper-1"]}>
          <div className={styles["wrapper-2"]}>
            <h5 className={styles.title}>Thank you !</h5>
            <p>
              Your tip has been submitted to{" "}
              {artistName ? artistName : "the artist"}. Your support is greatly
              appreciated!
            </p>
            <Button
              variant="contained"
              sx={{
                fontFamily: `"Varela Round", sans-serif`,
                backgroundColor: "#5892ff",
                "&:hover": {
                  bgcolor: "#4d4eaa",
                },
                mt: "1.5rem",
                padding: "10px",
              }}
            >
              <Link className="link" to={concertId ? `../concert/${concertId}` : '#'} relative="path">
                back to concert
              </Link>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
