import React from "react";
import classes from "./Header.module.css";
import cicadaImage from "../../images/cicada-image.jpg";
import backgoundImage from "../../images/abstract-02.jpg";

function Header() {
  return (
    <div className={classes.header}>
      <div className={classes.headerTitles}>
        <span className={classes.headerTitleSm}>
          <img
            src={cicadaImage}
            className={classes.cicadaImg}
            alt="backgound image"
          />
        </span>
        <span className={classes.headerTitleLg}>Cicada Music</span>
      </div>
      <img
        className={classes.headerImg}
        src={backgoundImage}
        alt="backgound image"
      />
    </div>
  );
}

export default Header;
