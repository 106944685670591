import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import classes from "./Sidebar.module.css";
import { Context } from "../../context/Context";

const Sidebar = () => {
  const [categories, setCategories] = useState([]);
  const PUBLIC_FOLDER = "http://localhost:5000/images/";
  const { user } = useContext(Context);

  useEffect(() => {
    // const getCategories = async () => {
    //   const res = await axios.get("/categories");
    //   setCategories(res.data);
    // };
    // try {
    //   getCategories();
    // } catch (err) {
    //   console.log(err);
    // }
  }, []);

  return (
    <div className={classes.sidebar}>
      {user && (
        <div className={classes.sidebarItem}>
          <span className={classes.sidebarTitle}>ABOUT ME</span>
          <img
            // src="https://scontent.ftlv2-1.fna.fbcdn.net/v/t1.6435-9/70990826_2958794167480752_4335528816819568640_n.jpg?_nc_cat=106&ccb=1-5&_nc_sid=8bfeb9&_nc_ohc=OmHjicTcDa8AX-9BaDB&_nc_ht=scontent.ftlv2-1.fna&oh=add9cd6919c4b0eec7949356f9928226&oe=61A71788"
            // src="https://scontent.ftlv2-1.fna.fbcdn.net/v/t1.6435-9/131419041_4179675872059236_6315853988360462261_n.jpg?_nc_cat=105&ccb=1-5&_nc_sid=09cbfe&_nc_ohc=JFg9eGkgLLoAX_D44gr&_nc_ht=scontent.ftlv2-1.fna&oh=595f6886396d9a72eac22a689082a15d&oe=61D8D59E"
            src={PUBLIC_FOLDER + user.profilePic}
            alt="Pictur didn't upload..."
          />
          <p>
            Mark was born on August 18, 1984 in Philadelphia.[9] His mother sang opera around the
            house, belonged to a church choir, and encouraged him to study music.[10] He started on
            accordion, then tried violin.
          </p>
        </div>
      )}

      <div className={classes.sidebarItem}>
        <span className={classes.sidebarTitle}>CATEGORIES</span>
        <ul className={classes.sidebarList}>
          {categories.map((category) => {
            return (
              <Link className="link" to={`/?category=${category.name}`} key={category._id}>
                <li className={classes.sidebarListItem + " " + classes.category}>
                  {category.name}
                </li>
              </Link>
            );
          })}

          {/* <li className={classes.sidebarListItem}>Life</li>
          <li className={classes.sidebarListItem}>Music</li>
          <li className={classes.sidebarListItem}>Style</li>
          <li className={classes.sidebarListItem}>Sport</li>
          <li className={classes.sidebarListItem}>Tech</li>
          <li className={classes.sidebarListItem}>Cinema</li> */}
        </ul>
      </div>
      <div className={classes.sidebarItem}>
        <span className={classes.sidebarTitle}>FOLLOW US</span>
        <div className={classes.sidebarSocial}>
          <i className={`${classes.sidebarIcon} fab fa-facebook-square`}></i>
          <i className={`${classes.sidebarIcon} fab fa-instagram-square`}></i>
          <i className={`${classes.sidebarIcon} fab fa-twitter-square`}></i>
          <i className={`${classes.sidebarIcon} fab fa-pinterest-square`}></i>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
