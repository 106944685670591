import { themeOptions } from "./Context";

const Reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN_START":
      return {
        ...state,
        user: null,
        isFetching: true,
        err: false,
        errorMessage: null,
      };
    case "LOGIN_SUCCESS":
      return {
        ...state,
        user: action.payload,
        isFetching: false,
        err: false,
        errorMessage: null,
      };
    case "LOGIN_FAILURE":
      return {
        ...state,
        user: null,
        isFetching: false,
        err: true,
        errorMessage: action.payload,
      };
    case "LOGOUT":
      return {
        ...state,
        user: null,
        isFetching: false,
        err: false,
        errorMessage: null,
      };
    case "UPDATE_START":
      return {
        ...state, // user: state.user
        isFetching: true,
        err: false,
        errorMessage: null,
      };
    case "UPDATE_SUCCESS":
      return {
        ...state,
        user: action.payload,
        isFetching: false,
        err: false,
        errorMessage: null,
      };
    case "UPDATE_FAILURE":
      return {
        ...state,
        user: state.user,
        isFetching: false,
        err: true,
        errorMessage: action.payload,
      };
    // SCROLLER
    case "UPDATE_SCROLLER":
      localStorage.setItem("isScroller", JSON.stringify(action.payload));
      return {
        ...state, // user: state.user
        isScroller: action.payload,
      };
    case "UPDATE_IS_CONCERT_ADMIN":
      localStorage.setItem("isConcertAdmin", JSON.stringify(action.payload));
      return {
        ...state, // user: state.user
        isConcertAdmin: action.payload,
      };

    // ORDER TYPE
    case "UPDATE_ORDER_TYPE":
      // localStorage.setItem("orderType", JSON.stringify(action.payload))
      return {
        ...state,
        orderType: action.payload,
      };

    // PURCHASE
    case "UPDATE_PURCHASE_STAGE":
      return {
        ...state,
        purchaseStage: action.payload,
      };
    case "SUBMIT_LEAD_START":
      return {
        ...state,
        purchaseStage: state.purchaseStage,
        isFetching: true,
        err: false,
        errorMessage: null,
      };
    case "SUBMIT_LEAD_SUCCESS":
      return {
        ...state,
        purchaseStage: action.payload,
        isFetching: false,
        err: false,
        errorMessage: null,
      };
    case "SUBMIT_LEAD_FAILURE":
      return {
        ...state,
        isFetching: false,
        err: true,
        errorMessage: action.payload,
      };
    case "SUBMIT_TIP_START":
      return {
        ...state,
        isFetching: true,
        err: false,
        errorMessage: null,
      };
    case "SUBMIT_TIP_SUCCESS":
      return {
        ...state,
        isFetching: false,
        err: false,
        errorMessage: null,
      };
    case "SUBMIT_TIP_FAILURE":
      return {
        ...state,
        isFetching: false,
        err: true,
        errorMessage: action.payload,
      };
    // Audient Interaction
    case "AUDIENT_ACTIVE_SCROLL_TOGGLE":
      return {
        ...state,
        audientIneraction: {
          activeScroll: !state.audientIneraction.activeScroll,
        },
      };
    case "AUDIENT_ACTIVE_SCROLL_SET_FALSE":
      return {
        ...state,
        audientIneraction: {
          activeScroll: false,
        },
      };
    case "AUDIENT_ACTIVE_SCROLL_SET_TRUE":
      return {
        ...state,
        audientIneraction: {
          activeScroll: true,
        },
      };
    // Admin mode
    case "IS_ADMIN_LYRICS_BROADCAST_MODE_SET_TRUE":
      return {
        ...state,
        adminMode: {
          isAdminLyricsBroadcastMode: true,
        },
      };
    case "IS_ADMIN_LYRICS_BROADCAST_MODE_SET_FALSE":
      return {
        ...state,
        adminMode: {
          isAdminLyricsBroadcastMode: false,
        },
      };

    // Theme
    case "TOGGLE_THEME":
      return {
        ...state,
        theme:
          state.theme === themeOptions.darkMode
            ? themeOptions.lightMode
            : themeOptions.darkMode,
      };

    // Concert Lyrics styles per song
    case "GLOBAL_CONCERT_STYLES_SET_CONCERT_ID":
      return {
        ...state,
        concertLyricsStyles: {
          concertId: action.payload
        },
      };

    case "GLOBAL_CONCERT_STYLES_ADD_SONG":
      return {
        ...state,
        concertLyricsStyles: {
          // concertId: action.payload.concertId,
          concertId: state.concertLyricsStyles.concertId,
          songs: {
            ...state.concertLyricsStyles.songs,
            [action.payload.sondId]: {
              pFontSize: action.payload.pFontSize,
              aFontSize: action.payload.aFontSize,
            },
          },
        },
      };

    default:
      return state;
  }
};

export default Reducer;
