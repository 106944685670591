import React, { useContext, useState } from "react";
import { Context } from "../../context/Context";
import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import VideoStream from "../videoStream/VideoStream";

const VideoStreams = ({ currentSong }) => {
  const { theme } = useContext(Context);

  const [currentStream, setCurrentStream] = useState(() => {
    if (currentSong.videoStreams.length === 0) return null;
    else return currentSong.videoStreams[0];
  });

  return (
    <>
      {currentSong.videoStreams.length > 0 && (
        <>
          <VideoStream
            channelId={
              currentStream?.channelId || currentSong.videoStreams[0].channelId
            }
          />
          <Box
            sx={{
              // display: "block",
              textAlign: "left",
              mt: "3px",
            }}
          >
            <ToggleButtonGroup
              variant="contained"
              aria-label="outlined primary button group"
              value={currentStream?.name}
              color="primary"
              fullWidth
              sx={{
                mt: "5px",
                background: theme.modeName === "dark" && "rgb(102, 102, 102)",
              }}
            >
              {currentSong.videoStreams.length > 1 &&
                currentSong.videoStreams.map((stream) => {
                  return (
                    <ToggleButton
                      key={stream._id}
                      value={stream.name}
                      aria-label="video stream window"
                      sx={{
                        color: theme.general.color,
                        boxShadow:
                          theme.modeName === "dark"
                            ? "0.7px 0.5px 0px 0px #fff"
                            : "0.5px 0.5px 0px 0px #464646",
                        "&.Mui-selected": {
                          // color: theme.modeName === "dark" ? "#c3ffc1" : "#1976d2"
                          color:
                            theme.modeName === "dark"
                              ? "rgb(140, 161, 255)"
                              : "#1976d2",
                        },
                      }}
                      onClick={() => setCurrentStream(stream)}
                      variant="contained"
                    >
                      {stream.name}
                    </ToggleButton>
                  );
                })}
            </ToggleButtonGroup>
          </Box>
        </>
      )}
    </>
  );
};

export default VideoStreams;
