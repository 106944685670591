import { useEffect, useState } from "react";
import thankYou2 from "../../images/thank-you-2.jpg";
import { Box } from "@mui/material";

const ThankyouLyricsFooter = () => {
  const [isDisplayed, setIsDisplayed] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsDisplayed(false);
    }, 10000);
  }, []);

  return (
    <Box
      sx={{
        textAlign: "center",
        fontFamily: `"Varela Round", sans-serif`,
        padding: "1rem",
        margin: "1rem",
      }}
    >
      {isDisplayed ? (
        <img
          style={{
            maxWidth: "85%",
          }}
          src={thankYou2}
          alt="Thank you image"
        />
      ) : (<Box sx={{height: '7rem', bgcolor: 'black'}}></Box>)}
    </Box>
  );
};

export default ThankyouLyricsFooter;
