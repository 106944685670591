import axios from "axios";
import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import classes from "./Register.module.css";
import CustomAlert from "../../components/CustomAlert/CustomAlert.jsx";
import TopBar from "../../components/topbar/TopBar";
import { PATH_SUFFIX } from "../../constants/frontAppConstants";
import { TextField, ThemeOptions, MenuItem } from "@mui/material";
import { themeOptions } from "../../context/Context";
import { bgcolor } from "@mui/system";

const Register = () => {
  // state of pass visability.
  const [showPassword, setShowPassword] = useState(false);

  const [role, setRole] = useState("publisher");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");

  // const [alertState, setAlertState] = useState(false);
  const [errCustomMsg, setErrCustomMsg] = useState(null);

  const submitHandler = async (event) => {
    event.preventDefault();
    try {
      const res = await axios.post(PATH_SUFFIX + "/auth/register", {
        username,
        email,
        password,
        role,
      });

      console.log(res);
      res.data && window.location.replace("/login");
      // CLEAR INPUTS
    } catch (err) {
      // TODO: implement a better message to the front end in case of mongoose validation errors
      console.log(err.response.data.error);
      if (err.response.data.error) {
        setErrCustomMsg(err.response.data.error);
        console.log({ err });
        console.log({ msg: err.response.data.error });
      }
    }
  };

  const removeAlertHandler = () => setErrCustomMsg(null);

  return (
    <div style={{...themeOptions.lightMode, width: "100vw", height: "100vh"}}>
      <TopBar />
      <div className={classes.register}>
        <span className={classes.registerTitle}>Register</span>
        <form className={classes.registerForm} onSubmit={submitHandler}>
          {/* <label htmlFor="role">Artist or Listener</label>

          <select
            value={role}
            className={classes.registerInput}
            name="role"
            id="role"
            onChange={(event) => {
              console.log(event.target.value);
              setRole(event.target.value);
            }}
          >
            <option className={classes.roleOption} selected value="publisher">
              Artist
            </option>
            <option className={classes.roleOption} value="user">
              Listener
            </option>
          </select> */}
          <label className={classes.label} htmlFor="role">Artist or Listener</label>
          <TextField
            name="role"
            id="role"
            size="small"
            select
            value={role}
            // helperText="Please select your country"
            onChange={(event) => {
              console.log(event.target.value);
              setRole(event.target.value);
            }}
            sx={{
              bgcolor: "rgb(232, 252, 255)"
            }}
          >
            <MenuItem value="publisher">Artist</MenuItem>
            <MenuItem value="user">Listener</MenuItem>
          </TextField>

          <label className={classes.label} htmlFor="username">Username</label>
          <input
            htmlFor="username"
            className={classes.registerInput}
            type="text"
            placeholder="Enter your username..."
            onChange={(event) => setUsername(event.target.value)}
          />
          <label>Email</label>
          <input
            className={classes.registerInput}
            type="text"
            placeholder="Enter your email..."
            onChange={(event) => setEmail(event.target.value)}
          />
          <label className={classes.label} htmlFor="password">Password</label>

          <input
            className={classes.registerInput}
            type={!showPassword ? "password" : "text"}
            placeholder="Enter password..."
            onChange={(event) => setPassword(event.target.value)}
          />
          <div className={classes.showPassword}>
            <input
              type="checkbox"
              onClick={() => {
                setShowPassword(!showPassword);
              }}
            />
            <p style={{color: "black"}}>View Password</p>
          </div>
          <button type="submit" className={classes.registerButon}>
            Register
          </button>
        </form>
        {errCustomMsg && (
          <CustomAlert
            msg_1={errCustomMsg}
            onRemoveAlert={removeAlertHandler}
          />
        )}
        <Link to="/login">
          <button className={classes.registerLoginButon}>Login</button>
        </Link>
      </div>
    </div>
  );
};

export default Register;
