import Card from "../UI/Card";
import Button from "../UI/Button";

import styles from "./CustomAlert.module.css";

const CustomAlert = (props) => {
  return (
    <Card passStyle={props.passStyle}>
      <div className={styles.alert} >
        <div className={styles.msg_1} style={props.passStyleMsg1}>
          <p style={{ fontSize: props.passStyleMsg1.fontSize }}>{props.msg_1}</p>
          <Button
            className={styles.btnAlert}
            onClick={() => props.onRemoveAlert()}
          >
            Okay
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default CustomAlert;
