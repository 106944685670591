import React, { useContext, useState } from "react";
import { Context } from "../../context/Context";
import SingleConcertAudiance from "../../components/concertsLyricsAudiance/SingleConcertAudiance";
import SingleConcertAdmin from "../../components/concertsLyricsAdmin/SingleConcertAdmin";
import { Box } from "@mui/material";

const Single = () => {
  const { isScroller, theme } = useContext(Context);

  return (
    <Box
      sx={{
        width: {
          xs: 400,
          sm: 400,
          md: 559,
          lg: 559,
          xl: 559,
        },

        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        ...theme.general,
      }}
    >
      {isScroller ? <SingleConcertAdmin /> : <SingleConcertAudiance />}
    </Box>
  );
};

export default Single;

{
  /* arrowUpClick={HandleArrowUpClick} arrowDownClick={HandleArrowDownClick} */
}
