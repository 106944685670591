import { useState, useEffect } from 'react';

/**
 * usePageVisibility Hook
 * A custom React hook that listens for the visibilitychange event and returns a boolean value indicating whether the current page is visible or hidden.
 * Usage
 * To use the usePageVisibility hook, simply import it from your React component and call it like any other hook:
 * @returns Boolean
 * The usePageVisibility hook returns a boolean value indicating whether the current page is visible or hidden. This value is updated whenever the visibilitychange event fires.
 * true if the page is currently visible
 * false if the page is currently hidden
 */
export default function usePageVisibility() {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setVisible(!document.hidden);
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return visible;
}