import styles from "./Card.module.css";

const Card = (props) => {
  const classes = styles.card + (props.className ? " " + props.className : "");
  // const classes = styles.card;
  // console.log({ propsClassName: props.className });

  return <div className={classes} style={props.passStyle}>{props.children}</div>;
};

export default Card;
