import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Modal,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";

import axios from "axios";

import CloseIcon from "@mui/icons-material/Close";
import { Context } from "../../context/Context";
import { PATH_SUFFIX } from "../../constants/frontAppConstants";
import { mailingModalLocalStorageOnFormSubmission } from "../../utils/localStorageUtils";

const modalStyle = {
  marginTop: "1rem",
  position: "absolute",
  top: "35%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "rgb(217, 220, 238)",
  border: "3px solid #1d1d1d",
  borderRadius: "7px",
  boxShadow: 24,
  p: 2,

  width: {
    xs: "70%",
    sm: "80%",
    md: "40%",
    xl: "30%",
  },
  // maxWidth: "50%",
  textAlign: "center",
};

const LyricsMailingListModal = ({
  isMailingListModalOpen,
  handleMailingListModalClose,
  concert,
}) => {
  const { dispatch } = useContext(Context);

  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState(null);
  const [emailError, setEmailError] = useState(null);

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    // Check if the email is valid and update the error state
    // const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    const isValidEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
      value
    );
    setEmailError(!isValidEmail);
  };

  const handleFirstNameChange = (e) => {
    const value = e.target.value;
    setFirstName(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // If there's an email error or empty email value, prevent form submission
    handleEmailChange(e)

    if (emailError) {
      return;
    }

    dispatch({ type: "SUBMIT_LEAD_START" });

    try {
      const res = await axios.post(PATH_SUFFIX + "/emailSubscribers", {
        email,
        firstName,
        concertId: concert._id,
        userId: concert.user._id,
      });
      dispatch({
        type: "SUBMIT_LEAD_SUCCESS",
      });
      handleMailingListModalClose();
      
      // handle Local Storage on form submittion
      mailingModalLocalStorageOnFormSubmission(concert.user._id)

    } catch (err) {
      err.response.data.error
        ? dispatch({
            type: "SUBMIT_LEAD_FAILURE",
            payload: err.response.data.error,
          })
        : dispatch({
            type: "SUBMIT_LEAD_FAILURE",
            payload: "something went wrong...",
          });

      console.error(err.response.data.error);
    }
  };

  return (
    <div
      id="modal-div"
      style={{
        margin: "0 2rem",
      }}
    >
      <Modal
        open={isMailingListModalOpen}
        onClose={handleMailingListModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Stack sx={{ flexDirection: "row-reverse", mb: "1rem" }}>
            <IconButton
              onClick={handleMailingListModalClose}
              sx={{
                padding: { xs: "7px" },
                "&:hover, &:active": {
                  backgroundColor: "rgba(40, 15, 140, 0.2)", // Optional: add a background color for hover and active states
                },
              }}
            >
              <CloseIcon sx={{ color: "rgb(40, 15, 140)" }} />
            </IconButton>
          </Stack>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {concert?.mailingListModal?.title
              ? concert?.mailingListModal?.title
              : `Join ${concert?.user?.username}’s mailing list!`}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 1 }}>
            {concert?.mailingListModal?.body
              ? concert?.mailingListModal?.body
              : "Learn about upcoming concerts and more"}
          </Typography>

          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              mt: 2,
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              required
              id="email"
              label="Email"
              type="email"
              inputProps={{ inputMode: "email" }}
              onChange={handleEmailChange} // Handle email input change
              error={emailError} // Set error based on email validation
              helperText={
                emailError ? "Please enter a valid email address." : ""
              }
            />
            <TextField
              id="first-name"
              label="First Name"
              onChange={handleFirstNameChange}
            />
            <Button
              type="submit"
              sx={{ mt: "1rem", mb: "1rem", width: "max-content" }}
              onClick={handleSubmit}
              variant="contained"
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default LyricsMailingListModal;
