import React, { useContext, useEffect, useRef, useState } from "react";
import { Context } from "../../context/Context";
import { Box, Button, ImageListItem, Stack } from "@mui/material";
import Lyrics_V2 from "../lyrics_V2/Lyrics_V2";
import VideoStreams from "../videoStreams/VideoStreams";
import Webcam from "react-webcam";

import FlipCameraIosIcon from "@mui/icons-material/FlipCameraIos";
import LinkedCameraIcon from "@mui/icons-material/LinkedCamera";
import NoPhotographyIcon from "@mui/icons-material/NoPhotography";

import { CAMERA_FACING } from "../../pages/singleShow/SingleShow";

const textShadow = { 
  dark: "0 0 3px #000", 
  light: "-1px -1px 0 #ffffff, 1px -1px 0 #ffffff, -1px 1px 0 #ffffff, 1px 1px 0 #ffffff",
}

const PassiveScrollMode = ({
  concert,
  currentSong,
  lyricsSliceIndex,
  boxHeight,
  cameraFacing,
  setCameraFacing,
  isCameraOn,
  setIsCameraOn,
  aspectRatio,
}) => {
  const { theme } = useContext(Context);

  const scrollingWindowRef = useRef(null);

  useEffect(() => {
    // scrollingWindowRef.current.scrollTop = 0;
    scrollingWindowRef.current.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [currentSong?.id]);

  const videoConstraints = {
    width: { min: 480 },
    height: { min: 720 },
    aspectRatio: aspectRatio,
    facingMode: cameraFacing,
  };

  return (
    <Box sx={{ height: boxHeight, position: "relative"}}>

      <Stack
        id="cur-song-name"
        sx={{
          display: "flex",
          flexDirection: "row",
          // marginTop: "0.5rem",
          marginTop: "-0.4rem",
          justifyContent: "space-between",
          alignItems: "center",
          position: "sticky",
          bgcolor: theme?.accordeon?.backgroundColor,
        }}
      >
        {!isCameraOn && (
          <Button
            sx={{ mr: "1rem", mb: "0.3rem" }}
            onClick={() => setIsCameraOn(true)}
          >
            <LinkedCameraIcon sx={{color: theme.modeName === "dark" && "white"}}/>
          </Button>
        )}
        {isCameraOn && (
            <>
              <Button sx={{ ml: "1rem", mb: "0.3rem" }} onClick={() => setIsCameraOn(false)}>
                <NoPhotographyIcon sx={{color: theme.modeName === "dark" && "white"}}/>
              </Button>
              
              <Button
                sx={{ mr: "1rem", mb: "0.3rem" }}
                onClick={() => {
                  setCameraFacing((prevState) =>
                    prevState === CAMERA_FACING.user
                      ? CAMERA_FACING.environment
                      : "user"
                  );
                }}
              >
                <FlipCameraIosIcon sx={{color: theme.modeName === "dark" && "white"}}/>
              </Button>

            </>
          )}
        </Stack>

      {
        isCameraOn &&
          <Webcam
            audio={false}
            mirrored={cameraFacing === CAMERA_FACING.user}
            videoConstraints={videoConstraints}
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              zIndex: 1,
            }}
          />
      }


      {/* <Box sx={{ height: boxHeight }}> */}
      

        <div
          id="scrollingWindow"
          ref={scrollingWindowRef}
          style={{
            // height: "88vh",
            height: boxHeight,
            overflowY: "scroll",
            // overflowY: "hidden",

            ...theme.accordeon,
            textShadow: isCameraOn ? theme.modeName === "dark" ? textShadow.dark : textShadow.light : 'none',
            backgroundColor: isCameraOn ? "rgba(0, 0, 0, 0)" : theme.accordeon?.backgroundColor,
            padding: "1rem",
            paddingBottom: "0",
            textAlign: "center",
            position: "relative",
            zIndex: 3,
          }}
        >
          <VideoStreams currentSong={currentSong} />
          {currentSong.photo !== "no-photo.jpg" &&
            currentSong.photo !== "" &&
            currentSong.photo && (
              <ImageListItem sx={{ marginBottom: "2rem" }}>
                <img
                  src={"/song_photo_uploads/" + currentSong.photo}
                  alt={currentSong.title}
                />
              </ImageListItem>
            )}
          <div
            style={{
              ...theme.accordeon,
              textShadow: isCameraOn ? theme.modeName === "dark" ? textShadow.dark : textShadow.light : 'none',
              backgroundColor: isCameraOn ? "rgba(0, 0, 0, 0)" : theme.accordeon?.backgroundColor,
              paddingBottom: "3rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "2rem"
            }}
            id="playlist"
          >
            {concert.hasFeatureLyricsBroadcast
              ?                 
                currentSong?.lyricsSliced[lyricsSliceIndex || 0]?.map(
                  (ln, i) => {
                    return (
                      <Lyrics_V2 key={i} songId={currentSong.id} lyrics={ln} />
                    );
                  }
                )
              : currentSong && (
                  <Lyrics_V2
                    songId={currentSong.id}
                    lyrics={currentSong.lyrics}
                  />
                )}
          </div>
        </div>
      {/* </Box> */}
    </Box>
  );
};

export default PassiveScrollMode;
