import React, { useContext } from "react";
import { Context } from "../../context/Context";
import classes from "./Lyrics.module.css";
const Lyrics = ({ lyrics }) => {
  const fixedText = lyrics.replace(/\n\r|\r\n|\r/g, "\n");
  const lines = fixedText.split("\n");

  const {theme} = useContext(Context);

  return (
    <div style={{...theme.general }} id='playlist'>
      {lines.map((line, index) => {
        let className = "";
        if (line.length === 0) {
          return <br key={index} />;
        }
        // const announceMatch = line.match(/^<announce>(.*?)<\/announce>$/m);
        const announceMatch = line.match(/^.*<announce>(.*?)<\/announce>.*$/m);
        if (announceMatch) {
          line = line.replace("<announce>", "");
          line = line.replace("</announce>", "");
          return (
            <p
              key={index}
              style={{
                margin: "1.4rem 0",
                // border: "2px solid #941212",
                // border: "2px solid #761294",
                border: "2px solid #7A49A5",
                borderRadius: "5px",
                padding: "7px",
                // width: "fitContent",
                // maxWidth: 'max-content',
              }}
            >
              {line}
            </p>
          );
        }
        return (
          <p className={classes[className]} key={index} >
            {line}
          </p>
        );
      })}
    </div>
  );
};

export default Lyrics;
