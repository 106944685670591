import { useEffect, useState } from "react";
import thankYou2 from "../../images/thank-you-2.jpg";
import cicadaGuitar from "../../images/cicada-guitar.jpg";
import { Box } from "@mui/material";

const ThankyouLyricsFooter = () => {
  const [isDisplayed, setIsDisplayed] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsDisplayed(false);
    }, 1000);
  }, []);

  return (
    <Box
      id="here"
      sx={{
        textAlign: "center",
        fontFamily: `"Varela Round", sans-serif`,
        overflow: "hidden",
        maxWidth: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {isDisplayed ? (
        <img
          style={{
            maxHeight: "100%",
            maxWidth: "300px",

            objectFit: "contain",
          }}
          src={thankYou2}
          alt="Thank you image"
        />
      ) : (
        <img
          style={{
            maxHeight: "200px",
            maxWidth: "300px",

            objectFit: "contain",
          }}
          src={cicadaGuitar}
          alt="Cicada Image"
        />

      )}
    </Box>
  );
};

export default ThankyouLyricsFooter;
